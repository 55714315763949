import { createSelector } from "@reduxjs/toolkit";

import { getError, isLoading } from "../../utils";

import type { RootStateType } from "../../types";
import type { BoatAtGate, PierType } from "./types";

const mockPier: PierType = {
  id: "",
  name: "",
  entranceId: "",
  countSensors: [],
  isOpen: false,
  availableCapacity: 0,
  capacity: 0,
  passengers: 0,
  reserve: 0,
};

const isWsConnected = ({ pier }: RootStateType) => pier.isWSConnected;

const getPier = ({ pier }: RootStateType) => pier.pier.data ?? mockPier;

const getGatesItems = ({ pier }: RootStateType) => pier.pierGates;

const getBoatsAtGateItems = ({ pier }: RootStateType) => pier.boatsAtGates;

const getWifiBoatLocationItems = ({ pier }: RootStateType) =>
  pier.wifiBoatLocation;

const getDepartedBoardChangingError = ({ pier }: RootStateType) =>
  getError(pier.departedBoatChanging)?.message;

const getBoatsOnRoute = ({ pier }: RootStateType) =>
  pier.boatsOnRoute.data ?? [];

const isBoatsOnRouteLoading = ({ pier }: RootStateType) =>
  isLoading(pier.boatsOnRoute);

const getSortedBoatsOnRoute = createSelector(getBoatsOnRoute, (boats) =>
  [...boats].sort((a, b) => a.name.localeCompare(b.name))
);

const getBoatsAtGates = createSelector(
  getBoatsAtGateItems,
  (boats): { [index: string]: BoatAtGate } =>
    boats.reduce((acc, item) => {
      return { ...acc, [item.pierGateId]: item };
    }, {})
);

const getEntrancesForTrafficManagement = createSelector(
  getGatesItems,
  getPier,
  getBoatsAtGates,
  (gates, pier, boatsAtGates) => ({
    gates: [
      ...gates.map((gate) => ({
        gateId: gate.id,
        sensors: gate.countSensors,
        name: gate.name,
        isMooredBoat: boatsAtGates[gate.id]?.departedDateTime === null,
        isWifiDeviceAlive: gate.isWifiDeviceAlive,
      })),
    ],
    pier: {
      sensors: pier.countSensors,
      name: "Вход",
    },
  })
);

export {
  getBoatsOnRoute,
  getPier,
  getGatesItems,
  getSortedBoatsOnRoute,
  getEntrancesForTrafficManagement,
  getBoatsAtGates,
  isWsConnected,
  getDepartedBoardChangingError,
  isBoatsOnRouteLoading,
  getWifiBoatLocationItems,
};
