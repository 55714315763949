import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { DataWithStatusType, CommonErrorType } from "../../types";
import type { UserDataType, UserWorkPlaceType, RouteType } from "./types";

type UserStateType = {
  user: DataWithStatusType<UserDataType>;
  selectedWorkPlace: UserWorkPlaceType;
  piersOnRoute: DataWithStatusType<RouteType[]>;
};
const initialState: UserStateType = {
  user: initData,
  selectedWorkPlace: null,
  piersOnRoute: initData,
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    fetchingUserData: (state) => {
      state.user.status = Status.LOADING;
      state.user.error = null;
    },
    fetchedUserData: (state, action: PayloadAction<UserDataType>) => {
      state.user.status = Status.SUCCESS;
      state.user.data = action.payload;
    },
    fetchedUserDataError: (state, action: PayloadAction<CommonErrorType>) => {
      state.user.status = Status.ERROR;
      state.user.error = action.payload;
    },
    fetchingPiersOnRoute: (state) => {
      state.piersOnRoute.status = Status.LOADING;
      state.piersOnRoute.error = null;
    },
    fetchedPiersOnRoute: (state, action: PayloadAction<RouteType[]>) => {
      state.piersOnRoute.status = Status.SUCCESS;
      state.piersOnRoute.data = action.payload;
    },
    fetchedPiersOnRouteError: (
      state,
      action: PayloadAction<CommonErrorType>
    ) => {
      state.piersOnRoute.status = Status.ERROR;
      state.piersOnRoute.error = action.payload;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      if (state.user.data) {
        state.user.data.authToken = action.payload;
      }
    },
    choosePier: (state, action: PayloadAction<UserWorkPlaceType | null>) => {
      state.selectedWorkPlace = action.payload;
    },
    logoutUser: (state) => {
      state.selectedWorkPlace = null;
      state.user = initData;
      state.piersOnRoute = initData;
    },
  },
});

export const actions = currentUserSlice.actions;
export default currentUserSlice.reducer;
