import { memo } from "react";
import classNames from "classnames";

import {
  getColorClassName,
  getSizeClassName,
  getTransformClassName,
  getWeightClassName,
  getWhiteSpaceClassName,
} from "./utils";
import {
  TextColor,
  TextSize,
  TextTransform,
  TextWeight,
  TextWhiteSpace,
} from "./types";

import styles from "./Text.module.css";

type TextPropsType = {
  size?: TextSize;
  children: string;
  color?: TextColor;
  weight?: TextWeight;
  transform?: TextTransform;
  whiteSpace?: TextWhiteSpace;
  className?: string;
  isFillFreeSpace?: boolean;
};

const Text: React.FC<TextPropsType> = memo(
  ({
    size = "medium",
    color = "gray",
    weight = "normal",
    transform = "none",
    whiteSpace = "nowrap",
    className = "",
    isFillFreeSpace = false,
    children,
  }) => {
    const colorClassName = getColorClassName(color);
    const weightClassName = getWeightClassName(weight);
    const sizeClassName = getSizeClassName(size);
    const transformClassName = getTransformClassName(transform);
    const whiteSpaceClassName = getWhiteSpaceClassName(whiteSpace);

    return (
      <>
        <div
          className={classNames(
            {
              [className]: !!className,
            },
            styles.text,
            whiteSpaceClassName,
            colorClassName,
            weightClassName,
            sizeClassName,
            transformClassName
          )}
        >
          {children}
        </div>
        {isFillFreeSpace && <span className={styles.fillSpace} />}
      </>
    );
  }
);

export default Text;
