import { useLayoutEffect, FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios, { InternalAxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";

import { getAuthToken } from "../store/currentUser/selectors";
import { updateAuthToken } from "../store/currentUser/actions";
import { useDispatch, useSelector } from "../store";

import type { CommonErrorType } from "../types";

const baseURL = `https://${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_URL_API}/${process.env.REACT_APP_API_VERSION}`;

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

type AxiosInterceptorType = {
  children: JSX.Element;
};

const AxiosInterceptor: FC<AxiosInterceptorType> = ({ children }) => {
  let navigate = useNavigate();
  const authToken = useSelector(getAuthToken);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const errResponseInterceptor = (error: any) => {
      let errorCodes = undefined;
      if (error.response?.status === 401) {
        navigate("./login");
      }
      if (error.response?.status === 400) {
        errorCodes = error.response?.data.messages.map(
          ({
            code,
            field,
            failedValue,
          }: {
            code: string;
            field?: string | null;
            failedValue?: string | null;
          }) => {
            return field || failedValue
              ? `${code}#${field ?? ""}#${failedValue ?? ""}`
              : code;
          }
        );
      }
      return Promise.reject({
        code: error.code,
        message: error.message,
        errorCodes,
      } as CommonErrorType);
    };

    const resRequestInterceptor = (response: InternalAxiosRequestConfig) => {
      if (authToken) {
        response.headers!["Authorization"] = authToken;
      }
      return response;
    };

    const res = instance.interceptors.response.use(
      null,
      errResponseInterceptor
    );
    const req = instance.interceptors.request.use(resRequestInterceptor);

    return () => {
      instance.interceptors.response.eject(res);
      instance.interceptors.request.eject(req);
    };
  }, [authToken, navigate, dispatch]);

  useEffect(() => {
    if (!authToken) {
      return;
    }
    const expToken = jwtDecode(authToken).exp;
    if (!expToken) {
      return;
    }
    const dateNowS = Date.now() / 1000;
    //TODO: вынести в константу
    const timeToUpdate = expToken - 5 * 60 - dateNowS;
    if (timeToUpdate < 0) {
      dispatch(updateAuthToken());
    } else {
      setTimeout(() => {
        dispatch(updateAuthToken());
      }, timeToUpdate * 1000);
    }
  }, [authToken, dispatch]);

  return children;
};

export { AxiosInterceptor };
export default instance;
