import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { BoatType } from "./types";
import type { DataWithStatusType, CommonErrorType } from "../../types";

type BoatsStateType = {
  boats: DataWithStatusType<BoatType[]>;
  selectedBoat: DataWithStatusType<BoatType>;
};

const initialState: BoatsStateType = {
  boats: initData,
  selectedBoat: initData,
};

export const boatSlice = createSlice({
  name: "boats",
  initialState,
  reducers: {
    fetchingBoats: (state) => {
      state.boats.status = Status.LOADING;
    },
    fetchedBoats: (state, action: PayloadAction<BoatType[]>) => {
      state.boats.status = Status.SUCCESS;
      state.boats.error = null;
      state.boats.data = action.payload;
    },
    fetchedBoatsError: (state, action: PayloadAction<CommonErrorType>) => {
      state.boats.status = Status.ERROR;
      state.boats.error = action.payload;
    },
    fetchingSelectedBoats: (state) => {
      state.selectedBoat.status = Status.LOADING;
    },
    fetchedSelectedBoat: (state, action: PayloadAction<BoatType | null>) => {
      state.selectedBoat.data = action.payload;
      state.selectedBoat.status = Status.INIT;
      state.selectedBoat.error = null;
    },
    updateBoat: (state, action: PayloadAction<BoatType | null>) => {
      if (state.boats.data) {
        if (action.payload) {
          const boatIndex = state.boats.data.findIndex(
            (x) => x.id === action.payload?.id
          );
          if (boatIndex >= 0) {
            state.boats.data[boatIndex] = action.payload;
          } else {
            state.boats.data.push(action.payload);
          }
        }
        state.selectedBoat.status = Status.SUCCESS;
      }
    },
    updateBoatErrors: (
      state,
      actions: PayloadAction<CommonErrorType | null>
    ) => {
      state.selectedBoat.error = actions.payload;
    },
    removeBoat: (state, actions: PayloadAction<BoatType["id"] | null>) => {
      state.boats.data?.splice(
        state.boats.data.findIndex((arrow) => arrow.id === actions.payload),
        1
      );
    },
    updateEnabled: (
      state,
      action: PayloadAction<{ id: BoatType["id"]; enabled: boolean }>
    ) => {
      if (state.boats.data) {
        if (action.payload) {
          const boatIndex = state.boats.data.findIndex(
            (x) => x.id === action.payload.id
          );
          if (boatIndex >= 0) {
            state.boats.data[boatIndex].enabled = action.payload.enabled;
          }
        }
      }
    },
  },
});

export const actions = boatSlice.actions;
export default boatSlice.reducer;
