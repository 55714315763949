import { Client, StompSubscription } from "@stomp/stompjs";
import { useEffect } from "react";

import { useDispatch, useSelector } from "../store";
import { getAuthToken } from "../store/currentUser/selectors";
import { setIsWSReady, setIsWSNotReady } from "../store/pier/actions";
import { logout, updateAuthToken } from "../store/currentUser/actions";

import type { CommonErrorType } from "../types";

let lastPierSub: StompSubscription | null = null;
let lastPierEventSub: StompSubscription | null = null;

const client = new Client({
  connectHeaders: {
    Authorization: "",
  },
  reconnectDelay: 3000,
  brokerURL: `wss://${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_URL_WS}`,
});

const getPier = <T>(pierId: string | null) => {
  if (lastPierSub && client.active) {
    lastPierSub.unsubscribe();
  }
  return new Promise<T | null>((resolve, reject) => {
    if (!client.active) {
      reject({ message: "Ошибка соедениия с webSocket" } as CommonErrorType);
      return;
    }
    if (!pierId) {
      resolve(null);
      return;
    }
    lastPierSub = client.subscribe(`/topic/pier/${pierId}/data`, ({ body }) => {
      resolve(JSON.parse(body));
    });
    client.publish({ destination: `/app/pier/${pierId}/data` });
  });
};

const pierEvent = <T>(pierId: string | null, cb: (data: T) => void) => {
  if (lastPierEventSub && client.active) {
    lastPierEventSub.unsubscribe();
  }
  if (pierId) {
    lastPierEventSub = client.subscribe(
      `/topic/pier/${pierId}/event`,
      ({ body }) => {
        cb(JSON.parse(body));
      }
    );
  }
};

const Stomp = () => {
  const authToken = useSelector(getAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken && !client.active) {
      client.connectHeaders = {
        Authorization: authToken.split(" ")[1],
      };
      client.onConnect = () => {
        dispatch(setIsWSReady());
        dispatch(updateAuthToken());
      };
      client.onDisconnect = () => {
        dispatch(setIsWSNotReady());
      };
      client.onWebSocketClose = () => {
        dispatch(setIsWSNotReady());
      };
      client.onStompError = () => {
        dispatch(logout());
      };
      client.activate();
    }
    if (!authToken && client.active) {
      client.deactivate();
    }
  }, [authToken, dispatch]);
  return null;
};

const defaultObj = {
  client,
  getPier,
  pierEvent,
};

export { Stomp };
export default defaultObj;
