import { memo } from "react";

import { getIconWithLabel, getIconWithLabelByTurnstileStatus } from "./utils";

import Panel from "../common/Panel";
import Text from "../common/Text";
import Indication from "../Indication";
import { TURNSTILE_STATUS } from "../../store/pier/types";

import styles from "./PierToolsStatus.module.css";

type PierToolsStatusPropsType = {
  turnstileStatus: TURNSTILE_STATUS;
  wifiToolsAlive: { name: string; alive?: boolean }[];
  className?: string;
};

const PierToolsStatus: React.FC<PierToolsStatusPropsType> = memo(
  ({ className = "", turnstileStatus, wifiToolsAlive }) => {
    const [icon, label] = getIconWithLabelByTurnstileStatus(turnstileStatus);
    return (
      <Panel className={className}>
        <Panel.Group title="Сервер управления турникетами">
          <div className={styles.content}>
            <Indication
              classNameLabel={styles.label}
              icon={icon}
              label={label}
            />
          </div>
        </Panel.Group>
        {!!wifiToolsAlive.length && (
          <Panel.Group title="Средства позиционирования судов">
            <div className={styles.positioningTools}>
              {wifiToolsAlive.map(({ name, alive }) => {
                const [aliveIcon, aliveLabel] = getIconWithLabel(alive);
                return (
                  <div key={name} className={styles.positionToolsItem}>
                    <Text color="dark">{name}</Text>
                    <Indication
                      classNameLabel={styles.label}
                      icon={aliveIcon}
                      label={aliveLabel}
                    />
                  </div>
                );
              })}
            </div>
          </Panel.Group>
        )}
      </Panel>
    );
  }
);

export default PierToolsStatus;
