import { useEffect } from "react";

import LoginForm from "../../components/LoginForm";
import LoginError from "../../components/Notification/LoginError";
import { tokenStorageItem } from "../../constants";
import { useSessionStorage } from "../../hooks";
import { useDispatch, useSelector } from "../../store";
import {
  fetchUser,
  fetchUserDataByToken,
} from "../../store/currentUser/actions";
import { getErrorLogin, isUserLogin } from "../../store/currentUser/selectors";

const LoginModule = () => {
  const isLoading = useSelector(isUserLogin);
  const errorLoginText = useSelector(getErrorLogin);

  const [storageAuthToken] = useSessionStorage<string>(tokenStorageItem);

  const dispatch = useDispatch();

  useEffect(() => {
    if (storageAuthToken) {
      dispatch(fetchUserDataByToken(storageAuthToken));
    }
  }, [dispatch, storageAuthToken]);

  const onSubmit = (login: string, password: string) => {
    dispatch(fetchUser(login, password));
  };

  return (
    <>
      <LoginForm isLoading={isLoading} onSubmit={onSubmit} />
      {errorLoginText && <LoginError />}
    </>
  );
};

export default LoginModule;
