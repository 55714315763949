import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { store } from "./store";
import Routes from "./Routes";
import { AxiosInterceptor } from "./api";
import { Stomp } from "./api/stomp";

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Provider store={store}>
        <>
          <Stomp />
          <AxiosInterceptor>
            <Routes />
          </AxiosInterceptor>
        </>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
