import { memo } from "react";

import Notification from "../components/Notification";
import Text from "../../common/Text";
import Button from "../../common/Button";
import { NotificationType } from "../types";

import styles from "./FinishBoardingNotification.module.css";

type FinishBoardingNotificationPropsType = {
  isOpen: boolean;
  boatName: string;
  gateName: string;
  onCancel: () => void;
  onSubmit: () => void;
};

const FinishBoardingNotification: React.FC<FinishBoardingNotificationPropsType> =
  memo(({ isOpen, boatName, gateName, onCancel, onSubmit }) => {
    return (
      <Notification
        type={NotificationType.WARNING}
        title="Завершение посадки"
        isOpen={isOpen}
        onCancel={onCancel}
        renderFooter={() => (
          <>
            <Button className={styles.actionButton} onClick={onSubmit}>
              Завершить посадку
            </Button>
            <Button className={styles.actionButton} onClick={onCancel}>
              Отмена
            </Button>
          </>
        )}
      >
        <div>
          <Text
            className={styles.headerText}
            weight="bold"
          >{`Посадка на судно «${boatName}» (выход ${gateName}) будет завершена`}</Text>
          <Text>Завершить посадку?</Text>
        </div>
      </Notification>
    );
  });

export default FinishBoardingNotification;
