import { memo } from "react";
import classNames from "classnames";

import Button from "../common/Button";
import Text from "../common/Text";
import Indication from "../Indication";
import { IconStatus } from "../../types";
import { MOORING_STATE } from "../../store/pier/types";

import styles from "./Entrance.module.css";

type EntrancePropsType = {
  className?: string;
  status: IconStatus.ERROR | IconStatus.SUCCESS;
  statusLabel?: string;
  title: string;
  disabledButton?: boolean;
  isSelectedBoat?: boolean;
  isMooredBoat?: boolean;
  mooredSate?: MOORING_STATE;
  onClick?: (newSate: MOORING_STATE) => void;
};

const Entrance: React.FC<EntrancePropsType> = memo(
  ({
    title,
    status,
    isSelectedBoat = false,
    disabledButton = false,
    isMooredBoat = false,
    statusLabel,
    className = "",
    mooredSate,
    onClick,
  }) => (
    <div
      className={classNames(styles.content, {
        [className]: !!className,
        [styles.contentSuccess]: status === IconStatus.SUCCESS,
        [styles.contentError]: status === IconStatus.ERROR,
      })}
    >
      <div className={styles.main}>
        <Text
          className={styles.title}
          size="xxlarge"
          weight="bold"
          transform="uppercase"
          color="dark"
        >
          {title}
        </Text>
      </div>
      <div className={styles.controls}>
        <Indication
          classNameLabel="Indication_label"
          icon={status === IconStatus.SUCCESS ? "ok" : "error"}
          label={statusLabel}
        />

        {onClick && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              height: "54px",
            }}
          >
            {isSelectedBoat && !isMooredBoat && (
              <>
                <Button
                  className={styles.button}
                  disabled={disabledButton}
                  onClick={() => onClick(MOORING_STATE.BOARDING_LANDING)}
                >
                  Высадка/Посадка
                </Button>
                <Button
                  className={styles.button}
                  disabled={disabledButton}
                  onClick={() => onClick(MOORING_STATE.LANDING)}
                >
                  Только высадка
                </Button>
              </>
            )}
            {isMooredBoat && mooredSate === MOORING_STATE.LANDING && (
              <>
                <Button
                  className={styles.button}
                  disabled={disabledButton}
                  onClick={() => onClick(MOORING_STATE.BOARDING)}
                >
                  Начать посадку
                </Button>
                <Button
                  className={styles.button}
                  disabled={disabledButton}
                  onClick={() => onClick(MOORING_STATE.DEPARTURE)}
                >
                  Отчалить без посадки
                </Button>
              </>
            )}
            {isMooredBoat && mooredSate !== MOORING_STATE.LANDING && (
              <>
                <Button
                  className={styles.button}
                  disabled={disabledButton}
                  onClick={() => onClick(MOORING_STATE.DEPARTURE)}
                >
                  Завершить посадку
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
);

export default Entrance;
