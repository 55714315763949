import { useCallback, useEffect, useMemo, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { withErrorBoundary } from "react-error-boundary";

import Select from "../../components/common/Select";
import Text from "../../components/common/Text";
import { CURRENT_PIER_STORAGE_ITEM, Role } from "../../constants";
import { useSessionStorage } from "../../hooks";
import { useDispatch, useSelector } from "../../store";
import {
  getUserRole,
  getPiersToSelect,
  getPiersOnRoutStatus,
} from "../../store/currentUser/selectors";
import {
  fetchPiersOnRoute,
  updateCurrentPier,
} from "../../store/currentUser/actions";

import styles from "./CurrentPier.module.css";

const mapStoreToProps = createSelector(
  getUserRole,
  getPiersOnRoutStatus,
  getPiersToSelect,
  (role, piersOnRoutStatus, piersWithRoute) => ({
    role,
    piersOnRoutStatus,
    piersWithRoute,
  })
);

const CurrentPier = () => {
  const {
    role,
    piersOnRoutStatus: { error, isLoading, isInit },
    piersWithRoute,
  } = useSelector(mapStoreToProps);
  const dispatch = useDispatch();
  const [storagePierId] = useSessionStorage<string>(CURRENT_PIER_STORAGE_ITEM);

  const [selectedRouteValue, setSelectedRoute] = useState<number | null>(null);
  const [selectedPierValue, setSelectedPier] = useState<string | null>(null);

  const isAdmin = role === Role.ADMIN || role === Role.OPERATOR;

  useEffect(() => {
    if (storagePierId !== "" && isAdmin) {
      setSelectedPier(storagePierId);
    }
  }, [storagePierId, isAdmin]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    if (isInit) {
      dispatch(fetchPiersOnRoute());
    }
  }, [isInit, dispatch, isAdmin]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(updateCurrentPier(selectedPierValue));
    }
  }, [dispatch, selectedPierValue, isAdmin]);

  useEffect(() => {
    if (selectedRouteValue === null && selectedPierValue !== null && !isInit) {
      const index = piersWithRoute.findIndex((route) =>
        route.piers.some((pier) => pier.value === selectedPierValue)
      );
      if (index >= 0) {
        setSelectedRoute(index);
      }
    }
  }, [selectedRouteValue, selectedPierValue, piersWithRoute, isInit]);

  const onSelectRouteHandler = useCallback(
    (value: string) => {
      const indexRoute = Number(value);
      setSelectedRoute(indexRoute);
      const pier = piersWithRoute.at(indexRoute)?.piers.at(0);
      if (pier) {
        setSelectedPier(pier.value);
        return;
      }
      setSelectedPier(null);
    },
    [piersWithRoute]
  );

  const onSelectPierHandler = useCallback((value: string) => {
    setSelectedPier(value);
  }, []);

  const routeOptions = useMemo(
    () =>
      piersWithRoute.map((route, index) => ({
        value: index.toString(),
        label: route.name,
      })),
    [piersWithRoute]
  );

  const piersOptions = useMemo(
    () =>
      selectedRouteValue !== null
        ? piersWithRoute[selectedRouteValue].piers
        : [],
    [piersWithRoute, selectedRouteValue]
  );

  if (role !== Role.ADMIN && role !== Role.OPERATOR) {
    return null;
  }

  //TODO: add common error
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Text size="semiLarge">Маршрут:</Text>
        <Select
          className={styles.select}
          disabled={isInit || isLoading}
          isLoading={isLoading}
          options={routeOptions}
          selected={selectedRouteValue?.toString()}
          onChange={onSelectRouteHandler}
        />
      </div>
      <div className={styles.content}>
        <Text size="semiLarge">Причал:</Text>
        <Select
          className={styles.select}
          disabled={isInit || isLoading || selectedRouteValue === null}
          options={piersOptions}
          isLoading={isLoading}
          selected={selectedPierValue ?? ""}
          onChange={onSelectPierHandler}
        />
      </div>
    </div>
  );
};

export default withErrorBoundary(CurrentPier, {
  fallback: (
    <Text>Что-то пошло не так. Пожалуйста, перезагрузите страницу</Text>
  ),
});
