import { ReactElement } from "react";

import Portal from "../../Portal";
import Button from "../../common/Button";
import SimpleButton from "../../common/SimpleButton";
import { ReactComponent as OkNotificationIcon } from "../../icons/OkNotificationIcon.svg";
import { ReactComponent as InfoNotificationIcon } from "../../icons/InfoNotificationIcon.svg";
import { ReactComponent as WarningNotificationIcon } from "../../icons/WarningNotificationIcon.svg";
import { ReactComponent as ErrorNotificationIcon } from "../../icons/ErrorNotificationIcon.svg";
import { ReactComponent as CloseIcon } from "../../icons/CloseIcon.svg";
import { NotificationType } from "../types";

import styles from "./Notification.module.css";

type NotificationPropsType = {
  type: NotificationType;
  title: string;
  isOpen: boolean;
  renderFooter?: () => ReactElement;
  children: JSX.Element;
  hiddenFooter?: boolean;
  onCancel: () => void;
};

const getIconByStatus = (type: NotificationType) => {
  switch (type) {
    case NotificationType.OK: {
      return <OkNotificationIcon />;
    }
    case NotificationType.INFO: {
      return <InfoNotificationIcon />;
    }
    case NotificationType.WARNING: {
      return <WarningNotificationIcon />;
    }
    case NotificationType.ERROR: {
      return <ErrorNotificationIcon />;
    }
  }
};

const Notification: React.FC<NotificationPropsType> = ({
  isOpen,
  type,
  onCancel,
  children,
  renderFooter,
  title,
  hiddenFooter = false,
}) => {
  const icon = getIconByStatus(type);

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.overlay}>
        <div className={styles.window}>
          <div className={styles.header}>
            {title}
            <SimpleButton className={styles.closeButton} onClick={onCancel}>
              <CloseIcon />
            </SimpleButton>
          </div>
          <div className={styles.content}>
            {icon}
            {children}
          </div>
          {!hiddenFooter && (
            <div className={styles.footer}>
              {renderFooter ? (
                renderFooter()
              ) : (
                <Button className={styles.actionButton} onClick={onCancel}>
                  Закрыть
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default Notification;
