import { useCallback, useEffect, useMemo } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { withErrorBoundary } from "react-error-boundary";

import Gate from "../../components/Gate";
import Text from "../../components/common/Text";
import Pier from "../../components/Pier";
import Button from "../../components/common/Button";
import { selectedWorkPlace } from "../../store/currentUser/selectors";
import { ReactComponent as ArrowLeftSwapIcon } from "../../components/icons/ArrowLeftSwapIcon.svg";
import { ReactComponent as ArrowRightSwapIcon } from "../../components/icons/ArrowRightSwapIcon.svg";
import { ReactComponent as ArrowSwapIcon } from "../../components/icons/ArrowSwapIcon.svg";
import { useSelector, useDispatch } from "../../store";
import {
  getSortedBoatsOnRoute,
  getPier,
  getGatesItems as getGates,
  getBoatsAtGates,
  isWsConnected,
  isBoatsOnRouteLoading as isBoatsOnRouteLoadingSelector,
  getWifiBoatLocationItems,
} from "../../store/pier/selectors";
import {
  fetchBoatsOnRoute,
  startBoarding,
  finishBoarding,
  getPierData,
  setUserBoatValue,
  updateSelectedBoat,
  swapGateSelectedValue,
} from "../../store/pier/actions";

import type {
  BoatAtGate,
  GateType,
  MOORING_STATE,
} from "../../store/pier/types";

import styles from "./Pier.module.css";

const SwapIcon = ({
  isHaveLeftGateValue,
  isHasRightGateValue,
}: {
  isHaveLeftGateValue: boolean;
  isHasRightGateValue: boolean;
}) => {
  if (isHaveLeftGateValue && isHasRightGateValue) {
    return <ArrowSwapIcon />;
  }
  if (isHaveLeftGateValue && !isHasRightGateValue) {
    return <ArrowRightSwapIcon />;
  }
  if (!isHaveLeftGateValue && isHasRightGateValue) {
    return <ArrowLeftSwapIcon />;
  } else {
    return null;
  }
};

const mapStoreToProps = createSelector(
  isWsConnected,
  isBoatsOnRouteLoadingSelector,
  selectedWorkPlace,
  getSortedBoatsOnRoute,
  getPier,
  getGates,
  getBoatsAtGates,
  getWifiBoatLocationItems,
  (
    isWsReady,
    isBoatsOnRouteLoading,
    selectedWorkPlace,
    boatsOnRoute,
    pier,
    gates,
    boatsAtGates,
    wifiBoatsLocation
  ) => ({
    isWsReady,
    selectedWorkPlace,
    boatsOnRoute,
    pier,
    gates,
    boatsAtGates,
    isBoatsOnRouteLoading,
    wifiBoatsLocation,
  })
);

//add handle error and loading data(for boats and boardingTime)
export const PierModule = () => {
  const {
    isWsReady,
    selectedWorkPlace,
    boatsOnRoute,
    pier,
    gates,
    boatsAtGates,
    isBoatsOnRouteLoading,
    wifiBoatsLocation,
  } = useSelector(mapStoreToProps);
  const dispatch = useDispatch();

  const getBoatsOnRoute = useCallback(() => {
    if (selectedWorkPlace) {
      dispatch(fetchBoatsOnRoute(selectedWorkPlace));
    }
  }, [dispatch, selectedWorkPlace]);

  useEffect(() => {
    getBoatsOnRoute();
  }, [getBoatsOnRoute]);

  useEffect(() => {
    dispatch(updateSelectedBoat());
  }, [wifiBoatsLocation, boatsAtGates, dispatch]);

  useEffect(() => {
    if (isWsReady) {
      dispatch(getPierData(selectedWorkPlace));
    }
  }, [selectedWorkPlace, isWsReady, dispatch]);

  const onStartBoardingHandler = useCallback(
    (gateId: string, boatId: string, newState: MOORING_STATE) => {
      dispatch(startBoarding(gateId, boatId, newState));
      getBoatsOnRoute();
    },
    [dispatch, getBoatsOnRoute]
  );

  const onFinishBoardHandler = useCallback(
    (gateId: string, boatId: string) => {
      dispatch(finishBoarding(gateId, boatId));
    },
    [dispatch]
  );

  const onSelectBoatHandler = useCallback(
    (gateId: GateType["id"], boatId: string) => {
      dispatch(setUserBoatValue({ gateId, value: boatId }));
    },
    [dispatch]
  );

  const swapSelectedValueHandler = useCallback(() => {
    dispatch(swapGateSelectedValue());
    dispatch(updateSelectedBoat());
  }, [dispatch]);

  const isShowSwapButton = useMemo(() => {
    return (
      Object.values(boatsAtGates).filter((x) => !x.departedDateTime).length <=
        0 && gates.some((x) => x.selectedBoat.value)
    );
  }, [boatsAtGates, gates]);

  if (!selectedWorkPlace) {
    return (
      <Text className={styles.notSelectedPier} size="large" color="dark">
        Выберите причал для начала работы
      </Text>
    );
  }

  return (
    <div className={styles.container}>
      <Pier
        name={pier.name}
        isOpen={pier.isOpen}
        filling={`${pier.passengers}/${pier.capacity}`}
        free={pier.availableCapacity.toString()}
        reserve={pier.reserve.toString()}
      />
      <div className={styles.gates}>
        {gates.map((gate) => {
          const boat: BoatAtGate | undefined = boatsAtGates[gate.id];
          return (
            <Gate
              isLoading={isBoatsOnRouteLoading}
              key={gate.id}
              gateId={gate.id}
              name={gate.name}
              selectedBoat={gate.selectedBoat.value}
              boatsOnRoute={boatsOnRoute}
              isBoardingAllowed={gate.isBoardingAllowed}
              mooredBoat={boat?.departedDateTime === null ? boat : null}
              onStartBoarding={onStartBoardingHandler}
              onFinishBoarding={onFinishBoardHandler}
              onSelectBoat={onSelectBoatHandler}
              onRefreshBoatsOnRoute={getBoatsOnRoute}
            />
          );
        })}

        <div className={styles.swapButtonContainer}>
          {isShowSwapButton && (
            <Button
              className={styles.swapButton}
              onClick={swapSelectedValueHandler}
            >
              <SwapIcon
                isHaveLeftGateValue={!!gates[0].selectedBoat.value}
                isHasRightGateValue={!!gates[1].selectedBoat.value}
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(PierModule, {
  fallback: (
    <Text>Что-то пошло не так. Пожалуйста, перезагрузите страницу</Text>
  ),
});
