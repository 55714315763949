import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { PierType, UserRoleType, UserType } from "./types";
import type { DataWithStatusType, CommonErrorType } from "../../types";

type UsersStateType = {
  users: DataWithStatusType<UserType[]>;
  roles: UserRoleType[];
  piers: DataWithStatusType<PierType[]>;
  selectedUser: DataWithStatusType<UserType>;
};

const initialState: UsersStateType = {
  users: initData,
  roles: [],
  piers: initData,
  selectedUser: initData,
};

export const pierSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    fetchingUsers: (state) => {
      state.users.status = Status.LOADING;
    },
    fetchedUsers: (
      state,
      action: PayloadAction<{ users: UserType[]; roles: UserRoleType[] }>
    ) => {
      state.users.status = Status.SUCCESS;
      state.users.error = null;
      state.users.data = action.payload.users;
      state.roles = action.payload.roles;
    },
    fetchedUsersError: (state, action: PayloadAction<CommonErrorType>) => {
      state.users.status = Status.ERROR;
      state.users.error = action.payload;
    },
    fetchingPiers: (state) => {
      state.piers.status = Status.LOADING;
    },
    fetchedPiers: (state, action: PayloadAction<PierType[]>) => {
      state.piers.status = Status.SUCCESS;
      state.piers.error = null;
      state.piers.data = action.payload;
    },
    fetchedPiersError: (state, action: PayloadAction<CommonErrorType>) => {
      state.piers.status = Status.ERROR;
      state.piers.error = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<UserType | null>) => {
      state.selectedUser.data = action.payload;
      state.selectedUser.status = Status.INIT;
      state.selectedUser.error = null;
    },
    updateSelectedUserToken: (
      state,
      action: PayloadAction<UserType["token"]>
    ) => {
      if (state.selectedUser.data) {
        state.selectedUser.data.token = action.payload;
      }
    },
    updateUser: (state, action: PayloadAction<UserType | null>) => {
      if (state.users.data) {
        if (action.payload) {
          const userIndex = state.users.data.findIndex(
            (x) => x.id === action.payload?.id
          );
          if (userIndex >= 0) {
            state.users.data[userIndex] = action.payload;
          } else {
            state.users.data.push(action.payload);
          }
        }
        state.selectedUser.status = Status.SUCCESS;
      }
    },
    updateUserErrors: (
      state,
      actions: PayloadAction<CommonErrorType | null>
    ) => {
      state.selectedUser.error = actions.payload;
    },
    updateEnabled: (
      state,
      action: PayloadAction<{ id: UserType["id"]; enabled: boolean }>
    ) => {
      if (state.users.data) {
        if (action.payload) {
          const boatIndex = state.users.data.findIndex(
            (x) => x.id === action.payload.id
          );
          if (boatIndex >= 0) {
            state.users.data[boatIndex].enabled = action.payload.enabled;
          }
        }
      }
    },
  },
});

export const actions = pierSlice.actions;
export default pierSlice.reducer;
