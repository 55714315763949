import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  CommonResponseType,
  FullPierType,
  PierCountSensorType,
  PierEntranceType,
  PierType,
  PierWifiDevice,
  TurnstileServerType,
} from "./types";
import type { DataWithStatusType, CommonErrorType } from "../../types";

type PiersStateType = {
  piers: DataWithStatusType<PierType[]>;
  selectedPier: DataWithStatusType<FullPierType>;
  updatePier: DataWithStatusType<null>;
};

const initialState: PiersStateType = {
  piers: initData,
  selectedPier: initData,
  updatePier: initData,
};

export const piersSlice = createSlice({
  name: "piersNew",
  initialState,
  reducers: {
    fetchingPiers: (state) => {
      state.piers.status = Status.LOADING;
    },
    fetchedPiers: (state, action: PayloadAction<PierType[]>) => {
      state.piers.status = Status.SUCCESS;
      state.piers.error = null;
      state.piers.data = action.payload;
    },
    fetchedPiersError: (state, action: PayloadAction<CommonErrorType>) => {
      state.piers.status = Status.ERROR;
      state.piers.error = action.payload;
    },
    fetchingSelectedPier: (state) => {
      state.selectedPier.status = Status.LOADING;
    },
    fetchedSelectedPier: (
      state,
      action: PayloadAction<FullPierType | null>
    ) => {
      state.selectedPier.data = action.payload;
      state.selectedPier.status = Status.INIT;
      state.selectedPier.error = null;
    },
    updatePier: (state, action: PayloadAction<PierType | null>) => {
      if (state.piers.data) {
        if (action.payload) {
          const pierIndex = state.piers.data.findIndex(
            (x) => x.id === action.payload?.id
          );
          if (pierIndex >= 0) {
            state.piers.data[pierIndex] = action.payload;
          } else {
            state.piers.data.push(action.payload);
          }
        }
        state.selectedPier.status = Status.SUCCESS;
      }
    },
    updatePierErrors: (
      state,
      actions: PayloadAction<CommonErrorType | null>
    ) => {
      state.selectedPier.status = Status.ERROR;
      state.selectedPier.error = actions.payload;
    },
    updatingPier: (state) => {
      state.updatePier.status = Status.INIT;
      state.updatePier.error = null;
    },
    resetUpdateError: (state) => {
      state.updatePier.status = Status.INIT;
      state.updatePier.error = null;
    },
    updateCommonData: (
      state,
      actions: PayloadAction<{ id: PierType["id"]; data: CommonResponseType }>
    ) => {
      if (state.piers.data) {
        const index = state.piers.data.findIndex(
          (x) => x.id === actions.payload.id
        );
        if (index >= 0) {
          state.piers.data[index].capacity = actions.payload.data.capacity;
          state.piers.data[index].name = actions.payload.data.name;
          state.piers.data[index].routeId =
            actions.payload.data.routePoint.route.id;
          state.piers.data[index].routeName =
            actions.payload.data.routePoint.route.name;
          state.piers.data[index].routePierOrder =
            actions.payload.data.routePoint.routePierOrder;
        }
        if (state.selectedPier.data) {
          state.selectedPier.data.common.capacity = String(
            actions.payload.data.capacity
          );
          state.selectedPier.data.common.externalId =
            actions.payload.data.externalId;
          state.selectedPier.data.common.name = actions.payload.data.name;
          state.selectedPier.data.common.routePoint = {
            routePierOrder: String(
              actions.payload.data.routePoint.routePierOrder
            ),
            route: {
              id: String(actions.payload.data.routePoint.route.id),
            },
          };
        }
        state.updatePier.status = Status.SUCCESS;
      }
    },
    updateTurnstileServer: (
      state,
      actions: PayloadAction<{
        data?: TurnstileServerType;
        isUseDefaultPassword?: boolean;
      }>
    ) => {
      if (state.selectedPier.data) {
        if (actions.payload.data) {
          state.selectedPier.data.turnstileServer = actions.payload.data;
        }
        if (actions.payload.isUseDefaultPassword !== undefined) {
          state.selectedPier.data.turnstileServer.useDefaultPassword =
            actions.payload.isUseDefaultPassword;
        }
      }
      state.updatePier.status = Status.SUCCESS;
    },
    updateWifiDevice: (
      state,
      actions: PayloadAction<{
        id: PierWifiDevice["id"];
        data?: PierWifiDevice;
        isUseDefaultPassword?: boolean;
        sensors: {
          entranceId: PierEntranceType["id"];
          delete: PierCountSensorType[];
          edit: PierCountSensorType[];
          create: PierCountSensorType[];
        };
      }>
    ) => {
      if (state.selectedPier.data) {
        if (actions.payload.data) {
          state.selectedPier.data.entrances =
            state.selectedPier.data.entrances.map((entrance) =>
              entrance.wifiDevice?.id === actions.payload.id
                ? {
                    ...entrance,
                    wifiDevice: actions.payload.data,
                  }
                : entrance
            );
        }
        if (actions.payload.isUseDefaultPassword !== undefined) {
          state.selectedPier.data.entrances =
            state.selectedPier.data.entrances.map((entrance) =>
              entrance.wifiDevice?.id === actions.payload.id
                ? {
                    ...entrance,
                    wifiDevice: {
                      ...entrance.wifiDevice!,
                      useDefaultPassword: actions.payload.isUseDefaultPassword!,
                    },
                  }
                : entrance
            );
        }
        const {
          entranceId,
          edit,
          create,
          delete: deleteItems,
        } = actions.payload.sensors;
        const entranceIndex = state.selectedPier.data.entrances.findIndex(
          (x) => x.id === entranceId
        );
        if (entranceIndex >= 0) {
          state.selectedPier.data.entrances[entranceIndex].countSensors =
            state.selectedPier.data.entrances[entranceIndex].countSensors
              .filter(
                (x) => !deleteItems.some((deleteItem) => deleteItem.id === x.id)
              )
              .concat([...create])
              .map((sensorItem) => {
                const editItem = edit.find((e) => e.id === sensorItem.id);
                if (editItem) {
                  return editItem;
                }
                return sensorItem;
              });
        }
      }

      state.updatePier.status = Status.SUCCESS;
    },
    removePier: (state, actions: PayloadAction<PierType["id"]>) => {
      state.piers.data?.splice(
        state.piers.data.findIndex((arrow) => arrow.id === actions.payload),
        1
      );
    },
    setUpdatePierError: (state, actions: PayloadAction<CommonErrorType>) => {
      state.updatePier.status = Status.ERROR;
      state.updatePier.error = actions.payload;
    },
    updateEnabled: (
      state,
      action: PayloadAction<{ id: PierType["id"]; enabled: boolean }>
    ) => {
      if (state.piers.data) {
        if (action.payload) {
          const pierIndex = state.piers.data.findIndex(
            (x) => x.id === action.payload.id
          );
          if (pierIndex >= 0) {
            state.piers.data[pierIndex].enabled = action.payload.enabled;
          }
        }
      }
    },
  },
});

export const actions = piersSlice.actions;
export default piersSlice.reducer;
