import api from "../../api";
import stomp from "../../api/stomp";

import type { BoatResponseType, MOORING_STATE } from "./types";

export const { getPier, pierEvent } = stomp;

export const getBoatsOnRoute = (pierId: string) =>
  api.get<BoatResponseType[]>(`piers/${pierId}/boats-free`);

export const setBoarding = (
  gateId: string,
  boatId: string,
  action: MOORING_STATE
) =>
  api.post("/boarding-time", {
    shipID: boatId,
    gateID: gateId,
    mooringState: action,
    date: new Date(),
  });

export const manualPasses = (id: string, inValue: number, outValue: number) =>
  api.post("/manual-passes", {
    id: id,
    in: inValue,
    out: outValue,
    date: new Date(),
  });
export const manualPassesOnDepartedBoat = (
  id: string,
  boatId: string,
  value: number
) =>
  api.post("/manual-passes-on-departed-boat", {
    entrance_id: id,
    boat_id: boatId,
    in: value <= 0 ? Math.abs(value) : 0,
    out: value > 0 ? value : 0,
  });

export const getDepartedBoat = (entranceId: string) =>
  api.get<BoatResponseType | null>(
    `pier-entrances/${entranceId}/boat-departed`
  );
