import { memo, useCallback } from "react";

import Button from "../common/Button";
import Panel from "../common/Panel";

import styles from "./TrafficManagement.module.css";

type TrafficManagementPropsType = {
  type: "pier" | "boat" | "departedBoat";
  entranceId?: string;
  sensorId: string;
  title: string;
  onChange: (
    sensorId: string,
    value: number,
    type: TrafficManagementPropsType["type"]
  ) => void;
  onOpenModal?: (gateId: string) => void;
};

const values = [1, -1, 5, -5];

const TrafficManagement: React.FC<TrafficManagementPropsType> = memo(
  ({ entranceId, sensorId, title, type, onChange, onOpenModal }) => {
    const onChangeHandler = (value: number) => () => {
      onChange(sensorId, value, type);
    };
    const onOpenModalHandler = useCallback(() => {
      if (onOpenModal) {
        onOpenModal(entranceId ?? "");
      }
    }, [entranceId, onOpenModal]);
    return (
      <Panel className={styles.content}>
        <Panel.Group title={title}>
          {type !== "departedBoat" ? (
            <div className={styles.controls}>
              {values.map((value) => (
                <Button key={value} onClick={onChangeHandler(value)}>
                  {`${value > 0 ? "+" : ""}${value}`}
                </Button>
              ))}
            </div>
          ) : (
            <Button
              disabled={!onOpenModal}
              className={styles.controlBtn}
              onClick={onOpenModalHandler}
            >
              Отчалившее судно
            </Button>
          )}
        </Panel.Group>
      </Panel>
    );
  }
);

export default TrafficManagement;
