import { TURNSTILE_STATUS } from "../../store/pier/types";
import { IndicationIcon } from "../Indication/types";

export const getIconWithLabel = (
  isAlive?: boolean
): [IndicationIcon, string] => {
  let icon: IndicationIcon = "ignore";
  let label = "Не определено";
  if (isAlive !== undefined) {
    icon = isAlive ? "ok" : "error";
    label = isAlive ? "Доступен" : "Недоступен";
  }
  return [icon, label];
};

export const getIconWithLabelByTurnstileStatus = (
  status: TURNSTILE_STATUS
): [IndicationIcon, string] => {
  let icon: IndicationIcon = "ignore";
  let label = "Не определено";
  switch (status) {
    case TURNSTILE_STATUS.OK: {
      icon = "ok";
      label = "Доступен";
      break;
    }
    case TURNSTILE_STATUS.LOCKED: {
      icon = "caution";
      label = "Заблокирован";
      break;
    }
    case TURNSTILE_STATUS.DISCONNECTED: {
      icon = "error";
      label = "Нет связи";
      break;
    }
    default:
      break;
  }
  return [icon, label];
};
