import { memo } from "react";
import classNames from "classnames";

import { IndicationIcon } from "./types";

import Text from "../common/Text";
import { ReactComponent as IndicationIgnoreIcon } from "../../components/icons/IndicationIgnoreIcon.svg";
import { ReactComponent as IndicationOffIcon } from "../../components/icons/IndicationOffIcon.svg";
import { ReactComponent as IndicationErrorIcon } from "../../components/icons/IndicationErrorIcon.svg";
import { ReactComponent as IndicationCautionIcon } from "../../components/icons/IndicationCautionIcon.svg";
import { ReactComponent as IndicationOkIcon } from "../../components/icons/IndicationOkIcon.svg";
import { ReactComponent as IndicationMoreIcon } from "../../components/icons/IndicationMoreIcon.svg";
import { ReactComponent as IndicationCheckMarkIcon } from "../../components/icons/IndicationCheckMarkIcon.svg";
import { ReactComponent as IndicationWarningIcon } from "../../components/icons/IndicationWarningIcon.svg";
import { ReactComponent as IndicationStartIcon } from "../../components/icons/IndicationStartIcon.svg";
import { ReactComponent as IndicationEndIcon } from "../../components/icons/IndicationEndIcon.svg";

import styles from "./Indication.module.css";

type IndicationPropsType = {
  className?: string;
  classNameLabel?: string;
  icon: IndicationIcon;
  label?: string;
};

const getIconByType = (iconType: IndicationIcon) => {
  switch (iconType) {
    case "ok": {
      return <IndicationOkIcon />;
    }
    case "error": {
      return <IndicationErrorIcon />;
    }
    case "caution": {
      return <IndicationCautionIcon />;
    }
    case "off": {
      return <IndicationOffIcon />;
    }
    case "ignore": {
      return <IndicationIgnoreIcon />;
    }
    case "more": {
      return <IndicationMoreIcon />;
    }
    case "checkMark": {
      return <IndicationCheckMarkIcon />;
    }
    case "warning": {
      return <IndicationWarningIcon />;
    }
    case "end": {
      return <IndicationEndIcon />;
    }
    case "start": {
      return <IndicationStartIcon />;
    }
  }
};

const Indication: React.FC<IndicationPropsType> = memo(
  ({ icon, label, className = "", classNameLabel = "" }) => {
    let iconElement = getIconByType(icon);
    return (
      <div
        className={classNames(styles.container, { [className]: !!className })}
      >
        <div className={styles.icon}>{iconElement}</div>
        {label && (
          <Text
            className={classNames({ [classNameLabel]: !!classNameLabel })}
            weight="bold"
          >
            {label}
          </Text>
        )}
      </div>
    );
  }
);

export default Indication;
