import { forwardRef } from "react";

import { ReactComponent as UserIcon } from "../../icons/UserIcon.svg";
import { ReactComponent as LockIcon } from "../../icons/LockIcon.svg";

import styles from "../LoginForm.module.css";

type InputType = "text" | "password";

type InputProps = {
  type: InputType;
  placeholder?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type, placeholder }, ref) => {
    return (
      <div className={styles.inputWrapper}>
        <div className={styles.inputLogo}>
          {type === "password" ? <LockIcon /> : <UserIcon />}
        </div>
        <input
          className={styles.input}
          type={type}
          placeholder={placeholder}
          ref={ref}
        />
      </div>
    );
  }
);

export { Input };
