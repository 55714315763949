import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RiverRouteType } from "./types";
import type { DataWithStatusType, CommonErrorType } from "../../types";

type RiverRouteStateType = {
  routes: DataWithStatusType<RiverRouteType[]>;
  selectedRoute: DataWithStatusType<RiverRouteType>;
};

const initialState: RiverRouteStateType = {
  routes: initData,
  selectedRoute: initData,
};

export const boatSlice = createSlice({
  name: "riverRoutes",
  initialState,
  reducers: {
    fetchingRoutes: (state) => {
      state.routes.status = Status.LOADING;
    },
    fetchedRoutes: (state, action: PayloadAction<RiverRouteType[]>) => {
      state.routes.status = Status.SUCCESS;
      state.routes.error = null;
      state.routes.data = action.payload;
    },
    fetchedRoutesError: (state, action: PayloadAction<CommonErrorType>) => {
      state.routes.status = Status.ERROR;
      state.routes.error = action.payload;
    },
    fetchingSelectedRoute: (state) => {
      state.selectedRoute.status = Status.LOADING;
    },
    fetchedSelectedRoute: (
      state,
      action: PayloadAction<RiverRouteType | null>
    ) => {
      state.selectedRoute.data = action.payload;
      state.selectedRoute.status = Status.INIT;
      state.selectedRoute.error = null;
    },
    updateRoute: (state, action: PayloadAction<RiverRouteType | null>) => {
      if (state.routes.data) {
        if (action.payload) {
          const route = { ...action.payload, id: String(action.payload?.id) };
          const routeIndex = state.routes.data.findIndex(
            (x) => x.id === route.id
          );
          if (routeIndex >= 0) {
            state.routes.data[routeIndex] = route;
          } else {
            state.routes.data.push(route);
          }
        }
        state.selectedRoute.status = Status.SUCCESS;
      }
    },
    updateRouteErrors: (
      state,
      actions: PayloadAction<CommonErrorType | null>
    ) => {
      state.selectedRoute.error = actions.payload;
    },
    removeRoute: (state, actions: PayloadAction<RiverRouteType["id"]>) => {
      state.routes.data?.splice(
        state.routes.data.findIndex((arrow) => arrow.id === actions.payload),
        1
      );
    },
    updateEnabled: (
      state,
      action: PayloadAction<{ id: RiverRouteType["id"]; enabled: boolean }>
    ) => {
      if (state.routes.data) {
        if (action.payload) {
          const riverIndex = state.routes.data.findIndex(
            (x) => x.id === action.payload.id
          );
          if (riverIndex >= 0) {
            state.routes.data[riverIndex].enabled = action.payload.enabled;
          }
        }
      }
    },
  },
});

export const actions = boatSlice.actions;
export default boatSlice.reducer;
