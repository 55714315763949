import { Spinner } from "./Spinner";

import styles from "../LoginForm.module.css";

type ButtonProps = {
  disabled?: boolean;
  isLoading?: boolean;
  className: string;
  children: React.ReactNode;
};

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  disabled = false,
  isLoading = false,
}) => {
  return (
    <button
      disabled={disabled}
      className={`${styles.button} ${className}`}
      type="submit"
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};
