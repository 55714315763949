import { lazy } from "react";
import {
  Routes as RoutesDom,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import { AppRoutes, PERMISSIONS_VIEW } from "./constants";
import { useSelector } from "./store";
import { getUserRole, isUserLogin } from "./store/currentUser/selectors";
import withPageSuspense from "./hoc/withPageSuspense";
import Layout from "./modules/Layout";
import Pier from "./pages/Pier";
import Login from "./pages/Login";

const Users = withPageSuspense(lazy(() => import("./pages/Users")));
const Boats = withPageSuspense(lazy(() => import("./pages/Boats")));
const Piers = withPageSuspense(lazy(() => import("./pages/Piers")));
const RiverRoutes = withPageSuspense(lazy(() => import("./pages/RiverRoutes")));
const Timetable = withPageSuspense(lazy(() => import("./pages/Timetable")));
const LogsPage = withPageSuspense(lazy(() => import("./pages/Logs")));

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const role = useSelector(getUserRole);
  const isLogin = useSelector(isUserLogin);

  if (!isLogin) {
    return <Navigate to={AppRoutes.Login} />;
  }
  const permission = PERMISSIONS_VIEW[pathname as AppRoutes]?.[role!];
  if (permission) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const Routes = () => {
  return (
    <RoutesDom>
      <Route path={AppRoutes.Login} element={<Login />} />
      <Route element={<Layout />}>
        <Route
          path={AppRoutes.Main}
          element={
            <PrivateRoute>
              <Pier />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.Users}
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.Boats}
          element={
            <PrivateRoute>
              <Boats />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.RiverRoutes}
          element={
            <PrivateRoute>
              <RiverRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.Logs}
          element={
            <PrivateRoute>
              <LogsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.Piers}
          element={
            <PrivateRoute>
              <Piers />
            </PrivateRoute>
          }
        />
        <Route
          path={AppRoutes.Timetable}
          element={
            <PrivateRoute>
              <Timetable />
            </PrivateRoute>
          }
        />
      </Route>
    </RoutesDom>
  );
};

export default Routes;
