import { createSelector } from "@reduxjs/toolkit";

import { Status } from "../../types";
import { Role } from "../../constants";
import { isSuccess, isInit, isLoading } from "../../utils";

import type { RouteType } from "./types";
import type { RootStateType } from "../../types";

const getAuthToken = ({ currentUser }: RootStateType): string | null =>
  currentUser.user.status === Status.SUCCESS && currentUser.user.data
    ? currentUser.user.data.authToken
    : null;

const isUserLogin = ({ currentUser }: RootStateType): boolean => {
  if (!isSuccess(currentUser.user)) {
    return false;
  }
  if (!currentUser.user.data?.authToken) {
    return false;
  }
  return true;
};

const getUserName = ({ currentUser }: RootStateType): string =>
  currentUser.user.data?.username ?? "";

const isFetchedToken = ({ currentUser }: RootStateType): boolean =>
  isSuccess(currentUser.user);

const getUserRole = ({ currentUser }: RootStateType): Role | null =>
  currentUser.user.data?.role ?? null;

const getErrorLogin = ({ currentUser }: RootStateType): string | null => {
  if (currentUser.user.status !== Status.ERROR) {
    return null;
  }
  return currentUser.user.error?.message ?? "Что-то пошло не так";
};

const isSelectedWorkPlace = ({ currentUser }: RootStateType): boolean =>
  !!currentUser.selectedWorkPlace;

const selectedWorkPlace = ({ currentUser }: RootStateType): string | null =>
  currentUser.selectedWorkPlace;

const getPiersOnRoutItem = ({ currentUser }: RootStateType): RouteType[] =>
  currentUser.piersOnRoute.data ?? [];

const getPiersToSelect = createSelector(getPiersOnRoutItem, (piersOnRoute) => {
  const mapSortedPier = (piers: RouteType["piers"]) =>
    [...(piers ?? [])]
      .sort((a, b) => a.ordinal - b.ordinal)
      .map((pier) => ({ value: pier.id, label: pier.name })) ?? [];
  return piersOnRoute?.map((route) => ({
    ...route,
    piers: mapSortedPier(route.piers),
  }));
});

const getPiersOnRoutStatus = createSelector(
  ({ currentUser }: RootStateType) => currentUser.piersOnRoute,
  (piersOnRoute) => ({
    isInit: isInit(piersOnRoute),
    isLoading: isLoading(piersOnRoute),
    error: piersOnRoute.error?.message,
  })
);

export {
  getAuthToken,
  isUserLogin,
  getUserName,
  getUserRole,
  getErrorLogin,
  isFetchedToken,
  isSelectedWorkPlace,
  selectedWorkPlace,
  getPiersToSelect,
  getPiersOnRoutStatus,
};
