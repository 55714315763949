import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { CommonErrorType, DataWithStatusType, Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { AdminUsersType, LogType } from "./types";

type LogsStateType = {
  logs: DataWithStatusType<LogType[]>;
  totalPages: number;
  users: DataWithStatusType<AdminUsersType[]>;
};

const initialState: LogsStateType = {
  logs: initData,
  totalPages: 0,
  users: initData,
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    fetchingLogs: (state) => {
      state.logs.status = Status.LOADING;
    },
    fetchedLogs: (state, action: PayloadAction<LogType[]>) => {
      state.logs.status = Status.SUCCESS;
      state.logs.error = null;
      state.logs.data = action.payload;
    },
    fetchedLogsError: (state, action: PayloadAction<CommonErrorType>) => {
      state.logs.status = Status.ERROR;
      state.logs.error = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    fetchingUsers: (state) => {
      state.users.status = Status.LOADING;
    },
    fetchedUsers: (state, action: PayloadAction<AdminUsersType[]>) => {
      state.users.status = Status.SUCCESS;
      state.users.error = null;
      state.users.data = action.payload;
    },
    fetchedUsersError: (state, action: PayloadAction<CommonErrorType>) => {
      state.users.status = Status.ERROR;
      state.users.error = action.payload;
    },
  },
});

export const actions = logsSlice.actions;
export default logsSlice.reducer;
