import { memo } from "react";
import classNames from "classnames";

import Text from "../common/Text";
import { ReactComponent as VesselIcon } from "../../components/icons/VesselIcon.svg";

import styles from "./Boat.module.css";

type BoatPropsType = {
  className?: string;
  name: string;
};

const Boat: React.FC<BoatPropsType> = memo(({ name, className = "" }) => (
  <div className={classNames(styles.container, { [className]: !!className })}>
    <VesselIcon className={styles.icon} />
    <Text className={styles.name} weight="bold">
      {name}
    </Text>
  </div>
));

export default Boat;
