import { createContext } from "react";

import { Role } from "../../constants";

const MenuContext = createContext<{
  isCollapse: boolean;
  activeLink: string;
  userRole: Role | null;
}>({
  isCollapse: false,
  activeLink: "",
  userRole: null,
});

export default MenuContext;
