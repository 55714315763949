import { useRef } from "react";

import { Input } from "./components/Input";
import { Button } from "./components/Button";

import { ReactComponent as Logo } from "../../components/icons/LogoIcon.svg";

import styles from "./LoginForm.module.css";

type LoginFormProps = {
  isLoading?: boolean;
  onSubmit: (login: string, password: string) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  isLoading = false,
}) => {
  const loginRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const onSubmitHandler = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const login = loginRef.current?.value ?? "";
    const password = passwordRef.current?.value ?? "";

    onSubmit(login, password);
  };

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.title}>
        <Logo />
        <span className={styles.titleText}></span>ИАС ПП
      </div>
      <form className={styles.form} onSubmit={onSubmitHandler}>
        <div className={styles.formInput}>
          <Input type="text" ref={loginRef} placeholder="Имя пользователя" />
        </div>
        <div className={styles.formInput}>
          <Input type="password" ref={passwordRef} placeholder="Пароль" />
        </div>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className={styles.formButton}
        >
          Войти
        </Button>
      </form>
      <p className={styles.copyright}>
        © АО "СИТРОНИКС", 2024. Все права защищены
      </p>
      {/* 
      tmp
      <div className={styles.contacts}>
        <a href="/">Техническая поддержка</a>
        <a href="/">Контакты</a>
      </div> */}
    </div>
  );
};

export default LoginForm;
