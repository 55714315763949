import { memo, ReactElement } from "react";
import classNames from "classnames";

import SimpleButton from "../SimpleButton";

import styles from "./Button.module.css";

type ButtonPropsType = React.ComponentProps<typeof SimpleButton> & {
  color?: "primary" | "secondary";
  children: ReactElement | string;
};

const Button: React.FC<ButtonPropsType> = memo(
  ({ children, color = "primary", className, ...props }) => (
    <SimpleButton
      {...props}
      className={classNames({
        [styles.button]: color === "primary",
        [styles.secondaryButton]: color === "secondary",
        [className!]: !!className,
      })}
    >
      {children}
    </SimpleButton>
  )
);
export default Button;
