import { useState, FC, ReactElement } from "react";
import classNames from "classnames";

import MenuContext from "./MenuContext";
import MenuItems from "./components/MenuItems";

import Text from "../common/Text";
import SimpleButton from "../common/SimpleButton";
import { ReactComponent as MenuCollapseIcon } from "../icons/MenuCollapseIcon.svg";
import { ReactComponent as MenuExpandIcon } from "../icons/MenuExpandIcon.svg";
import { Role } from "../../constants";

import styles from "./Menu.module.css";

type MenuComposition = {
  Group: typeof MenuItems;
};

type MenuPropsType = {
  children: ReactElement;
  currentPath: string;
  userRole: Role | null;
};

const Menu: FC<MenuPropsType> & MenuComposition = ({
  children,
  currentPath,
  userRole,
}) => {
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  return (
    <MenuContext.Provider
      value={{ isCollapse, activeLink: currentPath, userRole }}
    >
      <menu
        className={classNames({
          [styles.menuCollapse]: isCollapse,
          [styles.menuExpand]: !isCollapse,
        })}
      >
        {children}
        <SimpleButton
          onClick={() => setIsCollapse((old) => !old)}
          className={styles.collapseButton}
        >
          {isCollapse ? (
            <MenuExpandIcon />
          ) : (
            <>
              <MenuCollapseIcon />
              <Text className={styles.collapseButtonText}>Свернуть меню</Text>
            </>
          )}
        </SimpleButton>
      </menu>
    </MenuContext.Provider>
  );
};

Menu.Group = MenuItems;

export default Menu;
