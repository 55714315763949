import { memo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Text from "../../common/Text";

import styles from "../Menu.module.css";

type MenuItemPropsType = {
  children: string;
  to: string;
  isActive: boolean;
  isCollapse: boolean;
  icon: JSX.Element;
};

const MenuItem: React.FC<MenuItemPropsType> = memo(
  ({ to, isActive, icon, children, isCollapse }) => (
    <Link
      to={to}
      className={classNames(styles.menuItem, {
        [styles.menuItemActive]: isActive,
      })}
    >
      <div className={styles.icon}>{icon}</div>
      <span
        className={classNames({
          [styles.menuItemLabel]: !isCollapse,
          [styles.menuItemLabelHide]: isCollapse,
        })}
      >
        <Text color="light">{children}</Text>
      </span>
    </Link>
  )
);

export default MenuItem;
