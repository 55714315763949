import {
  PierResponseType,
  PierType,
  PierSide,
  EntranceResponseType,
  GateStatus,
  GateType,
  BoatAtGateResponseType,
  BoatAtGate,
  WifiBoatLocationType,
} from "./types";

const nameForPierSide = {
  [PierSide.FIRST]: "E1",
  [PierSide.SECOND]: "E2",
};

const mapDtoToGate = (entrance: EntranceResponseType): GateType => ({
  id: String(entrance.id),
  name: entrance.pierSide ? nameForPierSide[entrance.pierSide] : "Вход",
  isBoardingAllowed: entrance.entranceStatus === GateStatus.ALLOWED,
  countSensors: entrance.countSensors,
  selectedBoat: { type: "USER_CHOOSE", value: "" },
});

export const mapDtoToBoatAtGate = (
  boat: BoatAtGateResponseType
): BoatAtGate => ({
  id: String(boat.boatId),
  name: boat.boatTitle,
  departedDateTime: boat.departedDateTime,
  pierGateId: boat.pierEntranceId,
  capacity: boat.boatCapacity,
  passengers: boat.passengers,
  availableCapacity: boat.availableCapacity,
  reserve: boat.reserve,
  mooringState: boat.mooringState,
});

export const mapDtoToPier = (
  dto: PierResponseType
): {
  pier: PierType;
  leftGate: GateType | null;
  rightGate: GateType | null;
  boatsAtGates: BoatAtGate[];
  wifiBoatLocation: WifiBoatLocationType[];
} => {
  const pierEntrance = dto.pier.pierEntrances.find((x) => x.pierSide === null);
  const firstEntrance = dto.pier.pierEntrances.find(
    (x) => x.pierSide === PierSide.FIRST
  );
  const secondEntrance = dto.pier.pierEntrances.find(
    (x) => x.pierSide === PierSide.SECOND
  );
  let leftGate: GateType | null = firstEntrance
    ? mapDtoToGate(firstEntrance)
    : null;
  let rightGate: GateType | null = secondEntrance
    ? mapDtoToGate(secondEntrance)
    : null;

  const boatsAtGates = dto.boatOnRouteList.map(mapDtoToBoatAtGate);

  const wifiBoatLocation: WifiBoatLocationType[] = dto.wifiBoatLocationList.map(
    ({ boatId, pierEntranceId }) => ({ boatId, pierEntranceId })
  );

  return {
    leftGate,
    rightGate,
    boatsAtGates,
    wifiBoatLocation,
    pier: {
      id: dto.pier.id,
      name: dto.pier.metadata,
      entranceId: pierEntrance?.id ? String(pierEntrance.id) : "",
      countSensors: pierEntrance?.countSensors ?? [],
      isOpen: pierEntrance?.entranceStatus === GateStatus.ALLOWED,
      availableCapacity: dto.pier.availableCapacity,
      capacity: dto.pier.capacity,
      passengers: dto.pier.passengers,
      reserve: dto.pier.reserve,
    },
  };
};
