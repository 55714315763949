import { useState, useEffect, FC } from "react";
import { createPortal } from "react-dom";

type PortalPropsType = {
  children: JSX.Element;
};

const Portal: FC<PortalPropsType> = ({ children }) => {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    document.body.appendChild(el);
    return () => {
      document.body.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return createPortal(children, el);
};

export default Portal;
