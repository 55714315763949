import { memo } from "react";
import classNames from "classnames";

import styles from "./SimpleButton.module.css";

type SimpleButtonPropsType = {
  id?: string;
  className?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: JSX.Element | string;
  title?: string;
  form?: string;
};

const SimpleButton: React.FC<SimpleButtonPropsType> = memo(
  ({
    id,
    disabled = false,
    type = "button",
    className = "",
    children,
    title,
    form = "",
    onClick,
  }) => (
    <button
      id={id}
      disabled={disabled}
      type={type}
      title={title}
      className={classNames(styles.button, className)}
      onClick={onClick}
      onDoubleClick={(e) => e.stopPropagation()}
      form={form}
    >
      {children}
    </button>
  )
);

export default SimpleButton;
