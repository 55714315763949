import { actions } from "./reducer";
import { mapDtoToBoatAtGate, mapDtoToPier } from "./utils";
import {
  getPier,
  getBoatsOnRoute,
  manualPasses,
  setBoarding,
  manualPassesOnDepartedBoat,
  pierEvent,
  getDepartedBoat,
} from "./api";
import {
  EventMessageType,
  GateStatus,
  MOORING_STATE,
  TURNSTILE_STATUS,
} from "./types";

import type { EventDataType, PierResponseType } from "./types";
import type { CommonErrorType, AppDispatchType } from "../../types";

export const {
  fetchingPier,
  fetchedPier,
  fetchedPierError,
  setGates,
  setBoatsAtGates,
  fetchingBoatsOnRoute,
  fetchedBoatsOnRoute,
  fetchedBoatsOnRouteError,
  setIsWSNotReady,
  setIsWSReady,
  pierLoaded,
  updatePierData,
  updateGateStatus,
  updateBoatAtGate,
  updateCounterSensor,
  updateTurnstileStatus,
  removeBoat,
  removeBoatById,
  setDepartedBoatError,
  resetDepartedBoatError,
  updateWifiDeviceStatus,
  addWifiBoatLocation,
  removeWifiBoatLocation,
  setUserBoatValue,
  updateSelectedBoat,
  swapGateSelectedValue,
} = actions;

const fetchBoatsOnRoute = (pierId: string) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(fetchingBoatsOnRoute());
    getBoatsOnRoute(pierId)
      .then(({ data }) => {
        dispatch(
          fetchedBoatsOnRoute(
            data.map(({ id, ...boat }) => ({
              id: String(id),
              ...boat,
            }))
          )
        );
      })
      .catch((error: CommonErrorType) => {
        dispatch(fetchedBoatsOnRouteError(error));
      });
  };
};

const getPierData = (pierId: string | null) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(fetchingPier());
    dispatch(subPierEvent(pierId));
    getPier<PierResponseType>(pierId)
      .then((data) => {
        if (data) {
          const { pier, leftGate, rightGate, boatsAtGates, wifiBoatLocation } =
            mapDtoToPier(data);
          dispatch(updateTurnstileStatus(data.turnstileServerStatus));
          dispatch(fetchedPier(pier));
          dispatch(setBoatsAtGates(boatsAtGates));
          if (leftGate && rightGate) {
            dispatch(setGates([leftGate, rightGate]));
          }
          dispatch(addWifiBoatLocation(wifiBoatLocation));
        }
      })
      .catch((error) => {
        dispatch(
          fetchedPierError({
            code: error?.code ?? "",
            message: error?.message ?? "",
          })
        );
      });
  };
};
const subPierEvent = (pier: string | null) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(updateTurnstileStatus(TURNSTILE_STATUS.DISCONNECTED));
    pierEvent<EventDataType>(pier, (data) => {
      switch (data.messageType) {
        case EventMessageType.PIER_INFO: {
          dispatch(updatePierData(data));
          return;
        }
        case EventMessageType.ENTRANCE_INFO: {
          dispatch(
            updateGateStatus({
              id: String(data.entranceId),
              status: data.status === GateStatus.ALLOWED,
            })
          );
          return;
        }
        case EventMessageType.BOAT_INFO: {
          dispatch(updateBoatAtGate(mapDtoToBoatAtGate(data)));
          break;
        }
        case EventMessageType.COUNTER_INFO: {
          dispatch(updateCounterSensor(data));
          break;
        }
        case EventMessageType.TURNSTILE_INFO: {
          dispatch(updateTurnstileStatus(data.status));
          return;
        }
        case EventMessageType.WIFI_DEVICE_INFO: {
          dispatch(updateWifiDeviceStatus(data));
          return;
        }
        case EventMessageType.WIFI_BOAT_LOCATION_INFO: {
          if (data.active) {
            dispatch(addWifiBoatLocation(data));
          } else {
            dispatch(removeWifiBoatLocation(data.boatId));
          }
          return;
        }
        case EventMessageType.DEPARTED_BOAT_EDIT_NOT_ALLOWED: {
          dispatch(removeBoatById({ id: data.boatId }));
          dispatch(
            setDepartedBoatError({
              code: "",
              message: "На судно начата посадка на другом причале",
            })
          );
          return;
        }
        default:
          return;
      }
    });
  };
};

const finishBoarding = (gateId: string, boatId: string) => {
  return async (dispatch: AppDispatchType) => {
    setBoarding(gateId, boatId, MOORING_STATE.DEPARTURE).catch(
      (error: CommonErrorType) => {
        dispatch(fetchedPierError(error));
      }
    );
  };
};

const startBoarding = (
  gateId: string,
  boatId: string,
  newState: MOORING_STATE
) => {
  return async (dispatch: AppDispatchType) => {
    setBoarding(gateId, boatId, newState)
      .then(() => {
        dispatch(removeWifiBoatLocation(Number(boatId)));
        dispatch(setUserBoatValue({ gateId, value: "" }));
      })
      .catch((error: CommonErrorType) => {
        dispatch(fetchedPierError(error));
      });
  };
};

const changePassesOnBoat = (sensorId: string, value: number) => {
  return async (dispatch: AppDispatchType) => {
    const inValue = value > 0 ? value : 0;
    const outValue = value <= 0 ? Math.abs(value) : 0;
    manualPasses(sensorId, inValue, outValue).catch(
      (error: CommonErrorType) => {
        dispatch(fetchedPierError(error));
      }
    );
  };
};

const changePassesOnDepartedBoat = (
  gateId: string,
  boatId: string,
  value: number
) => {
  return async (dispatch: AppDispatchType) => {
    manualPassesOnDepartedBoat(gateId, boatId, value).catch(
      (error: CommonErrorType) => {
        if (error.code === "ERR_BAD_REQUEST") {
          dispatch(removeBoat({ id: boatId, gateId }));
          dispatch(
            setDepartedBoatError({
              code: "",
              message: "На судно начата посадка на другом причале",
            })
          );
        }
      }
    );
  };
};

const changePassesOnPier = (sensorId: string, value: number) => {
  return async (dispatch: AppDispatchType) => {
    const inValue = value <= 0 ? Math.abs(value) : 0;
    const outValue = value > 0 ? value : 0;
    dispatch(fetchingPier());
    manualPasses(sensorId, inValue, outValue)
      .then(() => {
        dispatch(pierLoaded());
      })
      .catch((error: CommonErrorType) => {
        fetchedPierError(error);
      });
  };
};

const checkDepartedBoat = (boatId: string, gateId: string) => {
  return async (dispatch: AppDispatchType) => {
    getDepartedBoat(gateId)
      .then(({ data }) => {
        if (!(String(data?.id) === boatId)) {
          dispatch(removeBoat({ id: boatId, gateId }));
          dispatch(
            setDepartedBoatError({
              code: "",
              message: "На судно начата посадка на другом причале",
            })
          );
        }
      })
      .catch((error) => dispatch(fetchedPierError(error)));
  };
};

export {
  fetchBoatsOnRoute,
  finishBoarding,
  startBoarding,
  changePassesOnBoat,
  changePassesOnPier,
  changePassesOnDepartedBoat,
  getPierData,
  checkDepartedBoat,
};
