import { memo, useMemo } from "react";

import Modal from "../Modal";
import Button from "../../common/Button";
import Characteristic from "../../Characteristic";
import Boat from "../../Boat";

import styles from "./DepartedShipModal.module.css";

type DepartedShipModalPropType = {
  isOpen: boolean;
  onCancel: () => void;
  onChange: (value: number) => void;
  boatName: string;
  filling: string;
  reserve: string;
  free: string;
};

const buttonValues = [1, -1, 5, -5];

const DepartedShipModal: React.FC<DepartedShipModalPropType> = memo(
  ({ isOpen, onCancel, boatName, onChange, filling, reserve, free }) => {
    const details = useMemo(
      () => [
        {
          label: "Пассажиры",
          value: filling,
        },
        {
          label: "Резерв",
          value: reserve,
        },
        {
          label: "Свободно мест",
          value: free,
        },
      ],
      [filling, reserve, free]
    );

    const onChangeHandler = (value: number) => () => {
      onChange(value);
    };
    return (
      <Modal
        isOpen={isOpen}
        title="Изменение свободных мест"
        onCancel={onCancel}
      >
        <>
          <div className={styles.content}>
            <Boat className={styles.boat} name={boatName} />
            <div className={styles.controls}>
              {buttonValues.map((value) => (
                <Button key={value} onClick={onChangeHandler(value)}>
                  {`${value > 0 ? "+" : ""}${value}`}
                </Button>
              ))}
            </div>
          </div>
          <div className={styles.characteristicContent}>
            <Characteristic
              className={styles.characteristic}
              details={details}
            />
          </div>
        </>
      </Modal>
    );
  }
);

export default DepartedShipModal;
