import { Suspense } from "react";

import Spinner from "../components/common/Spinner";

export default function withPageSuspense(Component: React.ComponentType) {
  return function WithSuspense() {
    return (
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner />
          </div>
        }
      >
        <Component />
      </Suspense>
    );
  };
}
