import { default as PierModule } from "../modules/Pier";
import CurrentPier from "../modules/CurrentPier";
import AvailableSeats from "../modules/AvailableSeats";
import PierSensors from "../modules/PierSensors";

import styles from "./index.module.css";

const Pier = () => {
  return (
    <div className={styles.pier}>
      <CurrentPier />
      <PierModule />
      <AvailableSeats />
      <PierSensors />
    </div>
  );
};

export default Pier;
