import { actions } from "./reducer";
import { loginUser, getUser, getPiersOnRout, refreshToken } from "./api";

import { CURRENT_PIER_STORAGE_ITEM, tokenStorageItem } from "../../constants";

import type { UserWorkPlaceType } from "./types";
import type { CommonErrorType, AppDispatchType } from "../../types";

export const {
  fetchingUserData,
  fetchedUserData,
  fetchedUserDataError,
  logoutUser,
  choosePier,
  fetchedPiersOnRoute,
  fetchedPiersOnRouteError,
  fetchingPiersOnRoute,
  updateToken,
} = actions;

export const fetchUser = (username: string, password: string) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(fetchingUserData());
    loginUser({ username, password })
      .then(({ authToken, pierId, ...userData }) => {
        dispatch(fetchedUserData({ ...userData, authToken }));
        dispatch(choosePier(pierId));
        window.sessionStorage.setItem(
          tokenStorageItem,
          JSON.stringify(authToken)
        );
      })
      .catch((error: CommonErrorType) => {
        dispatch(fetchedUserDataError(error));
      });
  };
};

export const fetchUserDataByToken = (token: string) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(fetchingUserData());
    getUser(token)
      .then(({ authToken, pierId, ...userData }) => {
        dispatch(fetchedUserData({ ...userData, authToken }));
        dispatch(choosePier(pierId));
      })
      .catch((error: CommonErrorType) => {
        dispatch(fetchedUserDataError(error));
      });
  };
};

export const updateCurrentPier = (pier: UserWorkPlaceType) => {
  return async (dispatch: AppDispatchType) => {
    dispatch(choosePier(pier));
    window.sessionStorage.setItem(
      CURRENT_PIER_STORAGE_ITEM,
      JSON.stringify(pier)
    );
  };
};

export const logout = () => {
  return async (dispatch: AppDispatchType) => {
    dispatch(logoutUser());
    try {
      window.sessionStorage.clear();
    } catch (error) {}
  };
};

export const fetchPiersOnRoute = () => {
  return async (dispatch: AppDispatchType) => {
    dispatch(fetchingPiersOnRoute());
    getPiersOnRout()
      .then(({ data }) => {
        dispatch(fetchedPiersOnRoute(data));
      })
      .catch((error: CommonErrorType) => {
        dispatch(fetchedPiersOnRouteError(error));
      });
  };
};

export const updateAuthToken = () => {
  return async (dispatch: AppDispatchType) => {
    refreshToken()
      .then((authToken) => {
        dispatch(updateToken(authToken));
        window.sessionStorage.setItem(
          tokenStorageItem,
          JSON.stringify(authToken)
        );
      })
      .catch(() => {});
  };
};
