import Notification from "../components/Notification";
import Text from "../../common/Text";
import { NotificationType } from "../types";

import styles from "./ManualPassesOnDepartedBoat.module.css";

type ManualPassesOnDepartedBoatPropsType = {
  isOpen: boolean;
  onCancel: () => void;
};

const ManualPassesOnDepartedBoat: React.FC<
  ManualPassesOnDepartedBoatPropsType
> = ({ isOpen, onCancel }) => {
  return (
    <Notification
      type={NotificationType.WARNING}
      title="Изменение свободных мест"
      isOpen={isOpen}
      onCancel={onCancel}
      hiddenFooter
    >
      <div>
        <Text className={styles.headerText} weight="bold">
          Изменение заполненности отчалившего судна невозможно
        </Text>
      </div>
    </Notification>
  );
};

export default ManualPassesOnDepartedBoat;
