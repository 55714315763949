export enum AppRoutes {
  Login = "/login",
  Main = "/",
  Users = "/users",
  Boats = "/boats",
  RiverRoutes = "/routes",
  Logs = "/logs",
  Piers = "/piers",
  Timetable = "/timetable",
}

export enum Role {
  ADMIN = "admin",
  OPERATOR = "operator",
  PIER_USER = "pierUser",
}
//TODO: rename
export const tokenStorageItem = "authToken";
export const CURRENT_PIER_STORAGE_ITEM = "currentPier";

export const PERMISSIONS_VIEW: {
  [key in AppRoutes]: { [key in Role]: boolean };
} = {
  [AppRoutes.Login]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: true,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.Main]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: true,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.Users]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.Boats]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.RiverRoutes]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.Logs]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: false,
  },
  [AppRoutes.Piers]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: true,
  },
  [AppRoutes.Timetable]: {
    [Role.ADMIN]: true,
    [Role.PIER_USER]: false,
    [Role.OPERATOR]: true,
  },
};
