import { CountSensorStatus } from "../../types";

export type DetailsType = {
  availableCapacity: number;
  capacity: number;
  passengers: number;
  reserve: number;
};

export type BoatType = {
  id: string;
  name: string;
};

export type BoatOnRoute = BoatType & {
  fromOtherRoute: boolean;
};

export type CountSensorType = {
  id: string;
  countSensorStatus: CountSensorStatus;
};

export enum MOORING_STATE {
  BOARDING = "BOARDING",
  LANDING = "LANDING",
  BOARDING_LANDING = "BOARDING_LANDING",
  DEPARTURE = "DEPARTURE",
}

export type GateType = {
  id: string;
  name: string;
  isBoardingAllowed: boolean;
  countSensors: CountSensorType[];
  isWifiDeviceAlive?: boolean;
  selectedBoat:
    | { type: "USER_CHOOSE"; value: string }
    | { type: "WIFI_CHOOSE"; value: string };
};

export type BoatAtGate = BoatType &
  DetailsType & {
    departedDateTime: string | null;
    pierGateId: GateType["id"];
    mooringState: MOORING_STATE;
  };

export type PierType = DetailsType & {
  id: string;
  name: string;
  entranceId: string;
  countSensors: CountSensorType[];
  isOpen: boolean;
};

export enum PierSide {
  "SECOND" = "SECOND",
  "FIRST" = "FIRST",
}

export enum GateStatus {
  ALLOWED = "PASSAGE_ALLOWED",
  REJECTED = "PASSAGE_REJECTED",
  EMPTY = "Empty",
}

export type EntranceResponseType = {
  id: number;
  pierSide: PierSide | null;
  entranceStatus: GateStatus;
  countSensors: CountSensorType[];
};

export type BoatAtGateResponseType = Omit<DetailsType, "capacity"> & {
  boatId: number;
  boatTitle: string;
  departedDateTime: string | null;
  boatCapacity: number;
  pierEntranceId: GateType["id"];
  mooringState: MOORING_STATE;
};

export type PierResponseType = {
  pier: {
    id: string;
    metadata: string;
    pierEntrances: EntranceResponseType[];
    availableCapacity: number;
    capacity: number;
    passengers: number;
    reserve: number;
  };
  boatOnRouteList: BoatAtGateResponseType[];
  turnstileServerStatus: TURNSTILE_STATUS;
  wifiBoatLocationList: WifiBoatLocationResponseType[];
};

export type WifiBoatLocationResponseType = {
  boatId: number;
  pierEntranceId: number;
  active: boolean;
};

export enum TURNSTILE_STATUS {
  OK = "OK",
  LOCKED = "LOCKED",
  DISCONNECTED = "DISCONNECTED",
}

export type UpdatedPierDataType = {
  availableCapacity: number;
  passengers: number;
  reserve: number;
};

export type UpdatedEntranceStatusType = {
  entranceId: number;
  status: GateStatus;
};

export type UpdateSensorStatus = {
  counterId: string;
  status: CountSensorStatus;
};

export type TurnstileStatusType = {
  status: TURNSTILE_STATUS;
};

export type DepartedBoatEditType = {
  boatId: BoatType["id"];
};

export type WifiDeviceStatusType = {
  pierEntranceId: number;
  alive: true;
};

export type WifiBoatLocationType = {
  boatId: number;
  pierEntranceId: number;
};

type PierInfoType = UpdatedPierDataType & {
  messageType: EventMessageType.PIER_INFO;
};
type EntranceInfoType = UpdatedEntranceStatusType & {
  messageType: EventMessageType.ENTRANCE_INFO;
};
type BoatInfoType = BoatAtGateResponseType & {
  messageType: EventMessageType.BOAT_INFO;
};

type CounterInfoType = UpdateSensorStatus & {
  messageType: EventMessageType.COUNTER_INFO;
};

type TurnstileInfoType = TurnstileStatusType & {
  messageType: EventMessageType.TURNSTILE_INFO;
};

type DepartedBoatEditNotAllowedType = DepartedBoatEditType & {
  messageType: EventMessageType.DEPARTED_BOAT_EDIT_NOT_ALLOWED;
};

type WifiDeviceInfoType = WifiDeviceStatusType & {
  messageType: EventMessageType.WIFI_DEVICE_INFO;
};

type WifiBoatLocationInfoType = WifiBoatLocationResponseType & {
  messageType: EventMessageType.WIFI_BOAT_LOCATION_INFO;
};

export type EventDataType =
  | PierInfoType
  | EntranceInfoType
  | BoatInfoType
  | CounterInfoType
  | TurnstileInfoType
  | DepartedBoatEditNotAllowedType
  | WifiDeviceInfoType
  | WifiBoatLocationInfoType;

export enum EventMessageType {
  PIER_INFO = "PIER_INFO",
  ENTRANCE_INFO = "ENTRANCE_INFO",
  BOAT_INFO = "BOAT_INFO",
  COUNTER_INFO = "COUNTER_INFO",
  TURNSTILE_INFO = "TURNSTILE_INFO",
  DEPARTED_BOAT_EDIT_NOT_ALLOWED = "DEPARTED_BOAT_EDIT_NOT_ALLOWED",
  WIFI_DEVICE_INFO = "WIFI_DEVICE_INFO",
  WIFI_BOAT_LOCATION_INFO = "WIFI_BOAT_LOCATION_INFO",
}

export type BoatResponseType = {
  id: number;
  name: string;
  fromOtherRoute: boolean;
};
