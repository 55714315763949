import classNames from "classnames";

import Text from "../../common/Text";

import styles from "../Characteristic.module.css";

type CharacteristicItemPropsType = {
  className?: string;
  label: string;
  value: string;
};

const CharacteristicItem: React.FC<CharacteristicItemPropsType> = ({
  label,
  value,
  className = "",
}) => {
  return (
    <li className={classNames(styles.detailItem, { [className]: !!className })}>
      <Text weight="medium" isFillFreeSpace>{`${label}`}</Text>
      <Text className="Item_value" weight="bold">
        {value}
      </Text>
    </li>
  );
};

export default CharacteristicItem;
