import { memo, useMemo } from "react";

import Text from "../common/Text";
import Entrance from "../../components/Entrance";
import Characteristic from "../Characteristic";
import { IconStatus } from "../../types";

import styles from "./Pier.module.css";

type PierPropsType = {
  name: string;
  isOpen: boolean;
  filling: string;
  reserve: string;
  free: string;
};

const Pier: React.FC<PierPropsType> = memo(
  ({ name, isOpen, filling, reserve, free }) => {
    const details = useMemo(
      () => [
        {
          label: "Заполнение",
          value: filling,
        },
        {
          label: "Резерв",
          value: reserve,
        },
        {
          label: "Свободно",
          value: free,
        },
      ],
      [filling, reserve, free]
    );
    return (
      <div className={styles.content}>
        <Text className={styles.title} weight="bold" color="dark">
          {name}
        </Text>
        <Entrance
          className={styles.entrance}
          status={isOpen ? IconStatus.SUCCESS : IconStatus.ERROR}
          statusLabel={isOpen ? "Открыт" : "Закрыт"}
          title="Вход"
        />
        <div className={styles.pier}>
          <Characteristic
            isBoldText
            className={styles.characteristic}
            details={details}
          />
        </div>
      </div>
    );
  }
);

export default Pier;
