import { useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";

import { AppRoutes } from "../../constants";
import { useDispatch, useSelector } from "../../store";
import { getUserName, getUserRole } from "../../store/currentUser/selectors";
import { logout } from "../../store/currentUser/actions";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { ReactComponent as UsersIcon } from "../../components/icons/UsersIcon.svg";
import { ReactComponent as BoatIcon } from "../../components/icons/BoatIcon.svg";
import { ReactComponent as StationIcon } from "../../components/icons/StationIcon.svg";
import { ReactComponent as RiverRouteIcon } from "../../components/icons/RiverRouteIcon.svg";
import { ReactComponent as LogsIcon } from "../../components/icons/LogsIcon.svg";
import { ReactComponent as PierIcon } from "../../components/icons/PierIcon.svg";
import { ReactComponent as TimetableIcon } from "../../components/icons/Timetable.svg";

import styles from "./Layout.module.css";

const mainMenuItems = [
  {
    path: AppRoutes.Main,
    label: "Причал",
    icon: <StationIcon />,
  },
];

const adminMenuItems = [
  {
    path: AppRoutes.Users,
    label: "Пользователи",
    icon: <UsersIcon />,
  },
  {
    path: AppRoutes.Boats,
    label: "Суда",
    icon: <BoatIcon />,
  },
  {
    path: AppRoutes.RiverRoutes,
    label: "Маршруты",
    icon: <RiverRouteIcon />,
  },
  {
    path: AppRoutes.Piers,
    label: "Причалы",
    icon: <PierIcon />,
  },
  {
    path: AppRoutes.Logs,
    label: "История изменений",
    icon: <LogsIcon />,
  },
];

const mapStoreToProps = createSelector(
  getUserRole,
  getUserName,
  (role, userName) => ({ userName, role })
);

const otherMenuItem = [
  {
    path: AppRoutes.Timetable,
    label: "Расписание",
    icon: <TimetableIcon />,
  },
];

const Layout = () => {
  const { userName, role } = useSelector(mapStoreToProps);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const logoutHandler = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <div className={styles.page}>
      <Header logout={logoutHandler} userName={userName} />
      <div className={styles.content}>
        <Menu currentPath={pathname} userRole={role}>
          <>
            <Menu.Group items={mainMenuItems} />
            <Menu.Group items={adminMenuItems} />
            <Menu.Group items={otherMenuItem} />
          </>
        </Menu>
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
