import { useCallback, useMemo, useState, memo, useEffect } from "react";

import Entrance from "../Entrance";
import Select from "../common/Select";
import MooredBoat from "../MooredBoat";
import FinishBoardingNotification from "../Notification/FinishBoardingNotification";
import { IconStatus } from "../../types";
import { MOORING_STATE } from "../../store/pier/types";

import type { BoatOnRoute, BoatAtGate, GateType } from "../../store/pier/types";

import styles from "./Gates.module.css";

type GatePropsType = {
  isLoading: boolean;
  gateId: string;
  name: string;
  selectedBoat: string;
  isBoardingAllowed: boolean;
  mooredBoat: BoatAtGate | null;
  boatsOnRoute: BoatOnRoute[];
  onStartBoarding: (
    gateId: string,
    boatId: string,
    newSate: MOORING_STATE
  ) => void;
  onFinishBoarding: (gateId: string, boatId: string) => void;
  onRefreshBoatsOnRoute: () => void;
  onSelectBoat: (gateId: GateType["id"], boatId: string) => void;
};

const Gate: React.FC<GatePropsType> = memo(
  ({
    isLoading,
    gateId,
    name,
    isBoardingAllowed,
    mooredBoat,
    boatsOnRoute,
    onFinishBoarding,
    onStartBoarding,
    onRefreshBoatsOnRoute,
    selectedBoat,
    onSelectBoat,
  }) => {
    const [isFinishBoardingModal, setFinishBoardingModalOpen] = useState(false);

    const onSelectBoatHandler = useCallback(
      (boatId: string) => {
        onSelectBoat(gateId, boatId);
      },
      [onSelectBoat, gateId]
    );

    const onCloseModalHandler = useCallback(
      () => setFinishBoardingModalOpen(false),
      []
    );

    useEffect(() => {
      if (!!mooredBoat) {
        onSelectBoat(gateId, "");
      }
    }, [mooredBoat, onSelectBoat, gateId]);

    const onRefreshBoatsOnRouteHandler = useCallback(() => {
      onRefreshBoatsOnRoute();
    }, [onRefreshBoatsOnRoute]);

    const onFinishBoardingHandler = useCallback(() => {
      if (mooredBoat) {
        onFinishBoarding(gateId, mooredBoat.id);
      }
      setFinishBoardingModalOpen(false);
    }, [mooredBoat, onFinishBoarding, gateId]);

    const onBoardingHandler = useCallback(
      (newState: MOORING_STATE) => {
        if (newState === MOORING_STATE.DEPARTURE) {
          setFinishBoardingModalOpen(true);
          return;
        }
        if (newState === MOORING_STATE.BOARDING) {
          onStartBoarding(gateId, mooredBoat?.id!, newState);
          return;
        }
        onStartBoarding(gateId, selectedBoat, newState);
      },
      [gateId, mooredBoat, selectedBoat, onStartBoarding]
    );

    const boatsOptions = useMemo(() => {
      const boatFromSameRouteOptions = boatsOnRoute
        .filter((boat) => !boat.fromOtherRoute)
        .map((boat) => ({ value: boat.id, label: boat.name }));
      const boatFromOtherRouteOptions: {
        value: string;
        label: string;
        disabled?: boolean;
      }[] = boatsOnRoute
        .filter((boat) => boat.fromOtherRoute)
        .map((boat) => ({ value: boat.id, label: boat.name }));
      if (boatFromOtherRouteOptions.length > 0) {
        boatFromOtherRouteOptions.unshift({
          value: "_",
          label: "__________________________________",
          disabled: true,
        });
      }
      return [...boatFromSameRouteOptions, ...boatFromOtherRouteOptions];
    }, [boatsOnRoute]);
    return (
      <>
        <div className={styles.container}>
          <Entrance
            className={styles.entrance}
            status={isBoardingAllowed ? IconStatus.SUCCESS : IconStatus.ERROR}
            statusLabel={
              isBoardingAllowed ? "Посадка разрешена" : "Посадка запрещена"
            }
            mooredSate={mooredBoat?.mooringState}
            title={name}
            isSelectedBoat={!!selectedBoat}
            isMooredBoat={!!mooredBoat}
            onClick={onBoardingHandler}
          />
          {mooredBoat ? (
            <MooredBoat
              className={styles.boat}
              name={mooredBoat.name}
              free={mooredBoat.availableCapacity.toString()}
              reserve={mooredBoat.reserve.toString()}
              filling={`${mooredBoat.passengers}/${mooredBoat.capacity}`}
            />
          ) : (
            <Select
              isLoading={isLoading}
              selected={selectedBoat}
              placeholder="Выберите судно"
              onChange={onSelectBoatHandler}
              options={boatsOptions}
              onClick={onRefreshBoatsOnRouteHandler}
            />
          )}
        </div>
        <FinishBoardingNotification
          gateName={name}
          boatName={mooredBoat?.name ?? ""}
          isOpen={isFinishBoardingModal}
          onCancel={onCloseModalHandler}
          onSubmit={onFinishBoardingHandler}
        />
      </>
    );
  }
);

export default Gate;
