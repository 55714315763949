import { Fragment } from "react";

import Portal from "../Portal";
import Button from "../common/Button";
import { ReactComponent as CloseIcon } from "../icons/CloseIcon.svg";

import styles from "./Modal.module.css";

type ModalType = {
  isOpen: boolean;
  onCancel: () => void;
  title: string;
  submitLabel?: string;
  children: JSX.Element;
  renderFooter?: (
    onCancel: ModalType["onCancel"],
    onSubmit: () => void
  ) => JSX.Element;
  omSubmit?: () => void;
};

const Modal: React.FC<ModalType> = ({
  isOpen,
  title,
  children,
  onCancel,
  omSubmit,
  submitLabel = "Ок",
  renderFooter,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Portal>
      <div className={styles.overlay}>
        <div className={styles.window}>
          <div className={styles.header}>
            {title}
            <button className={styles.simpleButton} onClick={onCancel}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles.content}>{children}</div>
          {omSubmit && (
            <div className={styles.footer}>
              {renderFooter ? (
                renderFooter(onCancel, omSubmit)
              ) : (
                <>
                  <Button className={styles.actionButton} onClick={omSubmit}>
                    {submitLabel}
                  </Button>
                  <Button className={styles.actionButton} onClick={onCancel}>
                    Отмена
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
