import { useMemo, FC, memo } from "react";
import classNames from "classnames";

import Boat from "../Boat";
import Characteristic from "../Characteristic";

import styles from "./MooredBoat.module.css";

type MooredBoatPropsType = {
  className?: string;
  name: string;
  filling: string;
  reserve: string;
  free: string;
};

const MooredBoat: FC<MooredBoatPropsType> = memo(
  ({ name, filling, reserve, free, className = "" }) => {
    const details = useMemo(
      () => [
        {
          label: "Пассажиры",
          value: filling,
        },
        {
          label: "Резерв",
          value: reserve,
        },
        {
          label: "Свободно",
          value: free,
        },
      ],
      [filling, reserve, free]
    );
    return (
      <div
        className={classNames(styles.container, { [className]: !!className })}
      >
        <Boat name={name} />
        <Characteristic className={styles.characteristic} details={details} />
      </div>
    );
  }
);
export default MooredBoat;
