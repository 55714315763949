import ErrorNotification from "./components/ErrorNotification";

const LoginError = () => (
  <ErrorNotification
    title="Неправильное имя пользователя или пароль"
    subTitle="Неправильное имя пользователя или пароль"
    errorText="Имя пользователя или пароль не найдены в системе. Проверьте правильность ввода, раскладку клавиатуры и состояние клавиши Caps Lock. Повторите ввод."
  />
);

export default LoginError;
