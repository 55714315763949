import { useContext } from "react";

import MenuItem from "./MenuItem";

import MenuContext from "../MenuContext";
import { PERMISSIONS_VIEW } from "../../../constants";

import type { MenuItemType } from "../types";

import styles from "../Menu.module.css";

type MenuItemsProps = {
  items: MenuItemType[];
};

const MenuItems: React.FC<MenuItemsProps> = ({ items }) => {
  const { activeLink, isCollapse, userRole } = useContext(MenuContext);
  if (userRole === null) {
    return null;
  }
  const itemsToView = items.filter((x) => PERMISSIONS_VIEW[x.path][userRole]);

  if (itemsToView.length === 0) {
    return null;
  }

  return (
    <ul className={styles.menuItems}>
      {itemsToView.map((item) => (
        <li key={item.path}>
          <MenuItem
            to={item.path}
            isCollapse={isCollapse}
            isActive={activeLink === item.path}
            icon={item.icon}
          >
            {item.label}
          </MenuItem>
        </li>
      ))}
    </ul>
  );
};

export default MenuItems;
