import { useCallback, useEffect, useMemo, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { withErrorBoundary } from "react-error-boundary";

import Text from "../../components/common/Text";
import DepartedShipModal from "../../components/Modal/DepartedShipModal";
import TrafficManagement from "../../components/TrafficManagement";
import ManualPassesOnDepartedBoat from "../../components/Notification/ManualPassesOnDepartedBoat";
import { useDispatch, useSelector } from "../../store";
import { isSelectedWorkPlace } from "../../store/currentUser/selectors";
import {
  changePassesOnBoat,
  changePassesOnPier,
  changePassesOnDepartedBoat,
  removeBoat,
  checkDepartedBoat,
  resetDepartedBoatError,
} from "../../store/pier/actions";
import {
  getBoatsAtGates,
  getEntrancesForTrafficManagement,
  getDepartedBoardChangingError,
} from "../../store/pier/selectors";

import styles from "./AvailableSeats.module.css";

const DEPARTED_BOAT_ALIVE_MIN = Number(
  process.env.REACT_APP_DEPARTED_BOAT_ALIVE_MIN
);
const DEPARTED_BOAT_ALIVE_MS =
  (Number.isNaN(DEPARTED_BOAT_ALIVE_MIN) ? 5 : DEPARTED_BOAT_ALIVE_MIN) *
  60 *
  1000;

type AvailableSeatsPropsType = {
  className?: string;
};

const matSateToProps = createSelector(
  getEntrancesForTrafficManagement,
  getBoatsAtGates,
  isSelectedWorkPlace,
  getDepartedBoardChangingError,
  (entrances, boatsAtGates, isSelectedPier, departedBoardChangingError) => ({
    entrances,
    boatsAtGates,
    isSelectedPier,
    departedBoardChangingError,
  })
);

const AvailableSeats: React.FC<AvailableSeatsPropsType> = ({
  className = "",
}) => {
  const {
    isSelectedPier,
    boatsAtGates,
    entrances: { gates, pier },
    departedBoardChangingError,
  } = useSelector(matSateToProps);
  const [selectedBoatId, setSelectedBoatId] = useState<string | null>(null);
  const dispatch = useDispatch();

  const departedBoat = useMemo(() => {
    const gateId = Object.keys(boatsAtGates).find(
      (gate) => boatsAtGates[gate].id === selectedBoatId
    );
    if (gateId) {
      const boat = boatsAtGates[gateId];
      if (boat.departedDateTime !== null) {
        return boat;
      }
    }
    setSelectedBoatId(null);
    return null;
  }, [selectedBoatId, boatsAtGates]);

  useEffect(() => {
    const timers = Object.keys(boatsAtGates).map((gateId) => {
      const boat = boatsAtGates[gateId];
      if (boat.departedDateTime !== null) {
        const deadline =
          new Date(boat.departedDateTime).getTime() +
          DEPARTED_BOAT_ALIVE_MS -
          new Date().getTime();
        return setTimeout(() => {
          dispatch(removeBoat({ id: boat.id, gateId: boat.pierGateId }));
        }, deadline);
      }
      return null;
    });
    return () => {
      timers.forEach((timer) => {
        if (timer) {
          clearTimeout(timer);
        }
      });
    };
  }, [boatsAtGates, dispatch]);

  const onChangeHandler = useCallback(
    (
      sensorId: string,
      value: number,
      type: "pier" | "boat" | "departedBoat"
    ) => {
      switch (type) {
        case "pier": {
          dispatch(changePassesOnPier(sensorId, value));
          return;
        }
        case "boat": {
          dispatch(changePassesOnBoat(sensorId, value));
          return;
        }
        case "departedBoat":
          return;
      }
    },
    [dispatch]
  );
  const onChangePassengerOnDepartedHandler = useCallback(
    (value: number) => {
      if (departedBoat) {
        dispatch(
          changePassesOnDepartedBoat(
            departedBoat?.pierGateId,
            departedBoat?.id,
            value
          )
        );
      }
    },
    [departedBoat, dispatch]
  );

  const hiddenDepartedBoatErrorHandler = useCallback(() => {
    dispatch(resetDepartedBoatError());
  }, [dispatch]);

  const onOpenModalHandler = useCallback(
    (gateId: string) => {
      const boat = boatsAtGates[gateId];
      setSelectedBoatId(boat.id);
      if (boat) {
        dispatch(checkDepartedBoat(boatsAtGates[gateId].id, boat.pierGateId));
      }
    },
    [boatsAtGates, dispatch]
  );

  const onCloseModal = useCallback(() => setSelectedBoatId(null), []);

  const [leftGate, rightGate] = useMemo(() => gates, [gates]);

  if (!isSelectedPier) {
    return null;
  }
  return (
    <>
      <div className={className}>
        <Text weight="bold">Свободные места</Text>
        <div className={styles.content}>
          {leftGate && (
            <TrafficManagement
              type={leftGate.isMooredBoat ? "boat" : "departedBoat"}
              entranceId={leftGate.gateId}
              sensorId={leftGate.sensors.at(0)?.id ?? ""}
              onChange={onChangeHandler}
              title={`На судне у ${leftGate.name}`}
              onOpenModal={
                boatsAtGates[leftGate.gateId] ? onOpenModalHandler : undefined
              }
            />
          )}
          <TrafficManagement
            type="pier"
            sensorId={pier.sensors.at(0)?.id ?? ""}
            onChange={onChangeHandler}
            title="На причале"
          />
          {rightGate && (
            <TrafficManagement
              type={rightGate.isMooredBoat ? "boat" : "departedBoat"}
              entranceId={rightGate.gateId}
              sensorId={rightGate.sensors.at(0)?.id ?? ""}
              onChange={onChangeHandler}
              title={`На судне у ${rightGate.name}`}
              onOpenModal={
                boatsAtGates[rightGate.gateId] ? onOpenModalHandler : undefined
              }
            />
          )}
        </div>
      </div>
      <DepartedShipModal
        isOpen={!!departedBoat}
        onCancel={onCloseModal}
        onChange={onChangePassengerOnDepartedHandler}
        boatName={departedBoat?.name ?? ""}
        filling={`${departedBoat?.passengers ?? 0}/${
          departedBoat?.capacity ?? 0
        }`}
        reserve={(departedBoat?.reserve ?? 0).toString()}
        free={(departedBoat?.availableCapacity ?? 0).toString()}
      />
      <ManualPassesOnDepartedBoat
        isOpen={!!departedBoardChangingError}
        onCancel={hiddenDepartedBoatErrorHandler}
      />
    </>
  );
};

export default withErrorBoundary(AvailableSeats, {
  fallback: (
    <Text>Что-то пошло не так. Пожалуйста, перезагрузите страницу</Text>
  ),
});
