import { ReactNode } from "react";
import classNames from "classnames";

import Text from "../Text";

import styles from "./Panel.module.css";

type PanelPropsType = {
  className?: string;
  children: ReactNode;
};

type PanelGroupPropsType = {
  title: string;
  className?: string;
  children: ReactNode;
};

type PanelComposition = {
  Group: typeof PanelGroup;
};

const PanelGroup: React.FC<PanelGroupPropsType> = ({
  title,
  className = "",
  children,
}) => (
  <div className={classNames({ [className]: !!className })}>
    <Text weight="bold">{title}</Text>
    {children}
  </div>
);

const Panel: React.FC<PanelPropsType> & PanelComposition = ({
  className = "",
  children,
}) => (
  <div className={classNames(styles.container, { [className]: !!className })}>
    {children}
  </div>
);

Panel.Group = PanelGroup;

export default Panel;
