import { Fragment } from "react";
import classNames from "classnames";

import Panel from "../common/Panel";
import Text from "../common/Text";
import Indication from "../Indication";
import { CountSensorStatus } from "../../types";

import type { IndicationIcon } from "../Indication/types";

import styles from "./PassengerCountingSensor.module.css";

type PassengerCountingSensorPropsType = {
  className?: string;
  entrances: { name: string; sensors: CountSensorStatus[] }[];
};

const getIconWithLabel = (
  sensor: CountSensorStatus
): { icon: IndicationIcon; label: string } => {
  let icon: IndicationIcon = "ignore";
  let label = "Не определено";
  switch (sensor) {
    case CountSensorStatus.OK: {
      icon = "ok";
      label = "Подключен";
      break;
    }
    case CountSensorStatus.DISCONNECTED: {
      icon = "error";
      label = "Нет связи";
      break;
    }
    case CountSensorStatus.COVERED: {
      icon = "caution";
      label = "Прикрыт";
      break;
    }
    case CountSensorStatus.TOO_BRIGHT: {
      icon = "caution";
      label = "Засвечен";
      break;
    }
    case CountSensorStatus.TOO_DARK: {
      icon = "caution";
      label = "Затемнен";
      break;
    }
    case CountSensorStatus.UNDEFINED: {
      icon = "ignore";
      label = "Не определено";
      break;
    }
  }
  return { icon, label };
};

const PassengerCountingSensor: React.FC<PassengerCountingSensorPropsType> = ({
  className = "",
  entrances,
}) => {
  return (
    <Panel
      className={classNames(styles.container, { [className]: !!className })}
    >
      <Panel.Group title="Датчики подсчета пассажиров">
        <div className={styles.content}>
          {entrances.map(({ name, sensors }) => (
            <Fragment key={name}>
              <Text color="dark">{`${name}:`}</Text>
              {sensors.map((sensor, index) => {
                const { icon, label } = getIconWithLabel(sensor);
                return (
                  <Indication
                    key={`${name}-${index}`}
                    classNameLabel={styles.label}
                    icon={icon}
                    label={label}
                  />
                );
              })}
              {[...Array(2 - sensors.length >= 0 ? 2 - sensors.length : 0)].map(
                (_, item) => (
                  <div key={item} />
                )
              )}
            </Fragment>
          ))}
        </div>
      </Panel.Group>
    </Panel>
  );
};

export default PassengerCountingSensor;
