import { memo } from "react";
import classNames from "classnames";

import Item from "./components/Item";
import { DetailsType } from "./types";

import styles from "./Characteristic.module.css";

type CharacteristicPropsType = {
  className?: string;
  details: DetailsType[];
  isBoldText?: boolean;
};

const Characteristic: React.FC<CharacteristicPropsType> = memo(
  ({ details, isBoldText = false, className = "" }) => (
    <ul className={classNames(styles.details, { [className]: !!className })}>
      {details.map((x, index) => (
        <Item
          key={x.label}
          value={x.value}
          label={x.label}
          className={classNames({
            [styles.boldValue]: isBoldText,
            [styles.largeValue]: index === 0 && isBoldText,
          })}
        />
      ))}
    </ul>
  )
);

export default Characteristic;
