import { useMemo } from "react";
import { withErrorBoundary } from "react-error-boundary";

import Text from "../../components/common/Text";
import PassengerCountingSensor from "../../components/PassengerCountingSensor";
import PierToolsStatus from "../../components/PierToolsStatus";
import { useSelector } from "../../store";
import { isSelectedWorkPlace } from "../../store/currentUser/selectors";
import { getEntrancesForTrafficManagement } from "../../store/pier/selectors";

import styles from "./PierSensors.module.css";

const PierSensors = () => {
  const isSelectedPier = useSelector(isSelectedWorkPlace);
  const entrances = useSelector(getEntrancesForTrafficManagement);
  const turnstileStatus = useSelector(({ pier }) => pier.turnstileStatus);

  const entranceSensors = useMemo(
    () => [
      {
        name: entrances.pier.name,
        sensors: entrances.pier.sensors.map(
          (sensor) => sensor.countSensorStatus
        ),
      },
      ...entrances.gates.map((x) => ({
        name: x.name,
        sensors: x.sensors.map((sensor) => sensor.countSensorStatus),
      })),
    ],
    [entrances]
  );

  const wifiToolsAlive = useMemo(
    () =>
      entrances.gates.map((gate) => ({
        name: gate.name,
        alive: gate.isWifiDeviceAlive,
      })),
    [entrances]
  );

  if (!isSelectedPier) {
    return null;
  }

  return (
    <>
      <div className={styles.sensors}>
        <PassengerCountingSensor entrances={entranceSensors} />
        <PierToolsStatus
          turnstileStatus={turnstileStatus}
          wifiToolsAlive={wifiToolsAlive}
        />
      </div>
    </>
  );
};

export default withErrorBoundary(PierSensors, {
  fallback: (
    <Text>Что-то пошло не так. Пожалуйста, перезагрузите страницу</Text>
  ),
});
