import { createSlice } from "@reduxjs/toolkit";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { PierType, FullPierType } from "./types";
import type { DataWithStatusType, CommonErrorType } from "../../types";

type PiersStateType = {
  piers: DataWithStatusType<PierType[]>;
  selectedPier: DataWithStatusType<FullPierType>;
};

const initialState: PiersStateType = {
  piers: initData,
  selectedPier: initData,
};

export const piersSlice = createSlice({
  name: "piers",
  initialState,
  reducers: {
    fetchingPiers: (state) => {
      state.piers.status = Status.LOADING;
    },
    fetchedPiers: (state, action: PayloadAction<PierType[]>) => {
      state.piers.status = Status.SUCCESS;
      state.piers.error = null;
      state.piers.data = action.payload;
    },
    fetchedPiersError: (state, action: PayloadAction<CommonErrorType>) => {
      state.piers.status = Status.ERROR;
      state.piers.error = action.payload;
    },
    fetchingSelectedPier: (state) => {
      state.selectedPier.status = Status.LOADING;
    },
    fetchedSelectedPier: (
      state,
      action: PayloadAction<FullPierType | null>
    ) => {
      state.selectedPier.data = action.payload;
      state.selectedPier.status = Status.INIT;
      state.selectedPier.error = null;
    },
    updatePier: (state, action: PayloadAction<PierType | null>) => {
      if (state.piers.data) {
        if (action.payload) {
          const pierIndex = state.piers.data.findIndex(
            (x) => x.id === action.payload?.id
          );
          if (pierIndex >= 0) {
            state.piers.data[pierIndex] = action.payload;
          } else {
            state.piers.data.push(action.payload);
          }
        }
        state.selectedPier.status = Status.SUCCESS;
      }
    },
    updatePierErrors: (
      state,
      actions: PayloadAction<CommonErrorType | null>
    ) => {
      state.selectedPier.error = actions.payload;
    },
  },
});

export const actions = piersSlice.actions;
export default piersSlice.reducer;
