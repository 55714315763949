import {
  TextColor,
  TextSize,
  TextTransform,
  TextWeight,
  TextWhiteSpace,
} from "./types";

import styles from "./Text.module.css";

export const getColorClassName = (color: TextColor): string => {
  switch (color) {
    case "dark": {
      return styles.darkText;
    }
    case "light": {
      return styles.lightText;
    }
    case "gray": {
      return styles.grayText;
    }
  }
};

export const getSizeClassName = (size: TextSize): string => {
  switch (size) {
    case "medium": {
      return styles.mediumSize;
    }
    case "semiLarge": {
      return styles.semiLargeSize;
    }
    case "large": {
      return styles.largeSize;
    }
    case "xlarge": {
      return styles.xlargeSize;
    }
    case "xxlarge": {
      return styles.xxlargeSize;
    }
  }
};

export const getWeightClassName = (weight: TextWeight): string => {
  switch (weight) {
    case "bold": {
      return styles.boldWeight;
    }
    case "normal": {
      return styles.normalWeight;
    }
    case "medium": {
      return styles.mediumWeight;
    }
  }
};

export const getTransformClassName = (transform: TextTransform): string => {
  switch (transform) {
    case "none": {
      return "";
    }
    case "uppercase": {
      return styles.uppercaseTransform;
    }
    case "lowercase": {
      return styles.lowercaseTransform;
    }
  }
};

export const getWhiteSpaceClassName = (whiteSpace: TextWhiteSpace): string => {
  switch (whiteSpace) {
    case "normal": {
      return styles.normalWhiteSpace;
    }
    case "nowrap": {
      return styles.nowrapWhiteSpace;
    }
  }
};
