import api from "../../api";
import { Role } from "../../constants";

import type {
  LoginRequestParamsType,
  LoginResponseType,
  PiersOnRouteResponseType,
} from "./types";

const getRole = (data: LoginResponseType): Role =>
  data.pierId === null ? Role.ADMIN : Role.PIER_USER;

export const loginUser = async (params: LoginRequestParamsType) => {
  return api
    .post<LoginResponseType>("/auth", params)
    .then(({ data, headers }) => {
      return {
        ...data,
        role: getRole(data),
        authToken: headers["authorization"],
      };
    });
};

export const getUser = async (token: string) => {
  const authHeader = "Authorization";
  const headers = {
    headers: {
      [authHeader]: token,
    },
  };
  return api.get<LoginResponseType>("/auth", headers).then(({ data }) => {
    return { ...data, role: getRole(data), authToken: token };
  });
};

export const refreshToken = async () =>
  api.patch("/auth").then(({ headers }) => headers["authorization"]);

export const getPiersOnRout = async () =>
  api.get<PiersOnRouteResponseType>("/routes/piers");
