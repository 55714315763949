import { FC, useCallback, useEffect, useState } from "react";

import Notification from "./Notification";

import Text from "../../common/Text";
import { NotificationType } from "../types";

import styles from "./Notification.module.css";

type ErrorNotificationType = {
  title?: string;
  subTitle?: string;
  errorText?: string;
  onCancel?: () => void;
};

const ErrorNotification: FC<ErrorNotificationType> = ({
  title = "Ошибка выполнения запроса к серверу",
  subTitle = "Что-то пошло не так",
  errorText = "При выполнении запроса произошла ошибка. Попробуйте повторить попытку позже",
  onCancel,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onCancelHandler = useCallback(() => {
    setIsOpen(false);
    onCancel?.();
  }, [onCancel]);

  return (
    <Notification
      onCancel={onCancelHandler}
      isOpen={isOpen}
      type={NotificationType.ERROR}
      title={title}
    >
      <div className={styles.errorNotification}>
        <Text weight="bold" size="semiLarge">
          {subTitle}
        </Text>
        <Text whiteSpace="normal">{errorText}</Text>
      </div>
    </Notification>
  );
};

export default ErrorNotification;
