import { memo } from "react";
import classNames from "classnames";

import { ReactComponent as SpinnerIcon } from "../../icons/SpinnerBlackSmallIcon.svg";
import { ReactComponent as Arrow } from "../../icons/DownArrowIcon.svg";
import { ReactComponent as IndicationErrorIcon } from "../../icons/IndicationErrorIcon.svg";

import styles from "./Select.module.css";

type OptionType = { label: string; value: string; disabled?: boolean };

type SelectPropsType = {
  className?: string;
  selected?: OptionType["value"];
  options: OptionType[];
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  required?: boolean;
  onChange?: (selected: OptionType["value"]) => void;
  onClick?: () => void;
  onBlur?: () => void;
};

const Select: React.FC<SelectPropsType> = memo(
  ({
    className = "",
    selected = "",
    options,
    placeholder = "Выберите значение",
    disabled = false,
    isLoading = false,
    hasError = false,
    errorMessage = "",
    required = true,
    onClick = () => {},
    onChange = () => {},
    onBlur,
  }) => {
    const error = hasError || !!errorMessage;
    return (
      <div className={styles.container}>
        <select
          disabled={disabled}
          required={required}
          className={classNames(styles.select, className, {
            [styles.error]: error,
          })}
          onClick={onClick}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            onChange(event.target.value)
          }
          onBlur={onBlur}
          value={selected!}
        >
          <option value="" className={styles.placeholderValue}>
            {placeholder}
          </option>
          {options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
        {!isLoading && (
          <div className={styles.icons}>
            {error && <IndicationErrorIcon title={errorMessage} />}
            <Arrow
              className={classNames({
                [styles.arrowDisabled]: disabled,
              })}
            />
          </div>
        )}
        {isLoading && <SpinnerIcon className={styles.spinnerContainer} />}
      </div>
    );
  }
);

export default Select;
