import { Status } from "../types";

import type { DataWithStatusType } from "../types";

export const initData = {
  status: Status.INIT,
  data: null,
  error: null,
};

export const isLoading = <T>(data: DataWithStatusType<T>): boolean =>
  data.status === Status.LOADING;

export const isInit = <T>(data: DataWithStatusType<T>): boolean =>
  data.status === Status.INIT;

export const getError = <T>(data: DataWithStatusType<T>) =>
  data.status === Status.ERROR ? data.error : null;

export const isLoaded = <T>(data: DataWithStatusType<T>): boolean =>
  data.status === Status.SUCCESS || data.status === Status.ERROR;

export const isSuccess = <T>(data: DataWithStatusType<T>): boolean =>
  data.status === Status.SUCCESS;
