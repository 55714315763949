import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

import type { TypedUseSelectorHook } from "react-redux";
import type { RootStateType, AppDispatchType } from "../types";

export const useDispatch: () => AppDispatchType = useReduxDispatch;
export const useSelector: TypedUseSelectorHook<RootStateType> =
  useReduxSelector;
