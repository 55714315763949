import { useCallback, useState } from "react";

export const useSessionStorage = <T>(
  key: string,
  initialValue: string = ""
): [T, (newValue: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const setValue = useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        if (typeof window !== "undefined") {
          window.sessionStorage.setItem(key, JSON.stringify(value));
        }
      } catch (error) {}
    },
    [key]
  );
  return [storedValue, setValue];
};
