import { memo } from "react";

import Text from "../common/Text";
import SimpleButton from "../common/SimpleButton";
import { ReactComponent as LogoIcon } from "../icons/LogoIcon.svg";
import { ReactComponent as UserIcon } from "../icons/UserHeaderIcon.svg";

import styles from "./Header.module.css";

type HeaderPropsType = {
  userName: string;
  logout: () => void;
};

const Header: React.FC<HeaderPropsType> = memo(({ userName, logout }) => (
  <header className={styles.header}>
    <span>
      <LogoIcon className={styles.productIcon} />
      <span className={styles.productName}>ИАС ПП</span>
    </span>
    <div className={styles.controls}>
      <span className={styles.user}>
        <UserIcon className={styles.userIcon} />
        <Text color="light">{userName}</Text>
      </span>
      <SimpleButton onClick={logout}>
        <Text color="light">Выход</Text>
      </SimpleButton>
    </div>
  </header>
));

export default Header;
