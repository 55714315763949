import { createSlice } from "@reduxjs/toolkit";

import { TURNSTILE_STATUS, WifiBoatLocationType } from "./types";

import { initData } from "../../utils";
import { Status } from "../../types";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { DataWithStatusType, CommonErrorType } from "../../types";
import type {
  GateType,
  BoatOnRoute,
  PierType,
  BoatAtGate,
  UpdatedPierDataType,
  UpdateSensorStatus,
  WifiDeviceStatusType,
} from "./types";

type PierStateType = {
  pier: DataWithStatusType<PierType>;
  pierGates: GateType[];
  boatsOnRoute: DataWithStatusType<BoatOnRoute[]>;
  boatsAtGates: BoatAtGate[];
  isWSConnected: boolean;
  turnstileStatus: TURNSTILE_STATUS;
  departedBoatChanging: DataWithStatusType<null>;
  wifiBoatLocation: WifiBoatLocationType[];
};

const initialState: PierStateType = {
  pier: initData,
  pierGates: [],
  boatsAtGates: [],
  boatsOnRoute: initData,
  isWSConnected: false,
  turnstileStatus: TURNSTILE_STATUS.DISCONNECTED,
  departedBoatChanging: initData,
  wifiBoatLocation: [],
};

export const pierSlice = createSlice({
  name: "pier",
  initialState,
  reducers: {
    fetchingPier: (state) => {
      state.pier.status = Status.LOADING;
    },
    fetchedPier: (state, action: PayloadAction<PierType>) => {
      state.pier.status = Status.SUCCESS;
      state.pier.error = null;
      state.pier.data = action.payload;
    },
    fetchedPierError: (state, action: PayloadAction<CommonErrorType>) => {
      state.pier.status = Status.ERROR;
      state.pier.error = action.payload;
    },
    pierLoaded: (state) => {
      state.pier.status = Status.SUCCESS;
    },
    updatePierData: (state, action: PayloadAction<UpdatedPierDataType>) => {
      if (state.pier.data) {
        const { availableCapacity, passengers, reserve } = action.payload;
        state.pier.data.availableCapacity = availableCapacity;
        state.pier.data.passengers = passengers;
        state.pier.data.reserve = reserve;
      }
    },
    updateGateStatus: (
      state,
      action: PayloadAction<{
        id: GateType["id"];
        status: GateType["isBoardingAllowed"];
      }>
    ) => {
      if (state.pier.data?.entranceId === action.payload.id) {
        state.pier.data.isOpen = action.payload.status;
      } else {
        state.pierGates.forEach((gate) => {
          if (gate.id === action.payload.id) {
            gate.isBoardingAllowed = action.payload.status;
          }
        });
      }
    },
    updateBoatAtGate: (state, action: PayloadAction<BoatAtGate>) => {
      const updatedBoat = action.payload;
      const foundBoatIndex = state.boatsAtGates.findIndex(
        (boat) => boat.pierGateId === updatedBoat.pierGateId
      );
      if (foundBoatIndex >= 0) {
        state.boatsAtGates[foundBoatIndex] = updatedBoat;
      } else {
        state.boatsAtGates.push(updatedBoat);
      }
    },
    removeBoat: (
      state,
      action: PayloadAction<{ id: string; gateId: string }>
    ) => {
      const { id, gateId } = action.payload;
      state.boatsAtGates = state.boatsAtGates.filter(
        (boat) => !(boat.id === id && boat.pierGateId === gateId)
      );
    },
    removeBoatById: (state, action: PayloadAction<{ id: string }>) => {
      state.boatsAtGates = state.boatsAtGates.filter(
        (boat) =>
          !(boat.id === action.payload.id && boat.departedDateTime !== null)
      );
    },

    updateCounterSensor: (state, action: PayloadAction<UpdateSensorStatus>) => {
      if (state.pier.data) {
        const { counterId, status } = action.payload;
        state.pier.data.countSensors.forEach((sensor) => {
          if (sensor.id === counterId) {
            sensor.countSensorStatus = status;
          }
        });
        state.pierGates.forEach((gate) =>
          gate.countSensors.forEach((sensor) => {
            if (sensor.id === counterId) {
              sensor.countSensorStatus = status;
            }
          })
        );
      }
    },

    updateTurnstileStatus: (state, action: PayloadAction<TURNSTILE_STATUS>) => {
      state.turnstileStatus = action.payload;
    },

    updateWifiDeviceStatus: (
      state,
      action: PayloadAction<WifiDeviceStatusType>
    ) => {
      const { pierEntranceId, alive } = action.payload;
      const gateIndex = state.pierGates.findIndex(
        (gate) => gate.id === String(pierEntranceId)
      );
      if (gateIndex >= 0) {
        state.pierGates[gateIndex].isWifiDeviceAlive = alive;
      }
    },

    fetchingBoatsOnRoute: (state) => {
      state.boatsOnRoute.status = Status.LOADING;
    },
    fetchedBoatsOnRoute: (state, action: PayloadAction<BoatOnRoute[]>) => {
      state.boatsOnRoute.status = Status.SUCCESS;
      state.boatsOnRoute.error = null;
      state.boatsOnRoute.data = action.payload;
    },
    fetchedBoatsOnRouteError: (
      state,
      action: PayloadAction<CommonErrorType>
    ) => {
      state.boatsOnRoute.status = Status.ERROR;
      state.boatsOnRoute.error = action.payload;
    },
    setGates: (state, action: PayloadAction<GateType[]>) => {
      state.pierGates = action.payload;
    },
    setBoatsAtGates: (state, action: PayloadAction<BoatAtGate[]>) => {
      state.boatsAtGates = action.payload;
    },
    setIsWSReady: (state) => {
      state.isWSConnected = true;
    },
    setIsWSNotReady: (state) => {
      state.isWSConnected = false;
    },
    setDepartedBoatError: (state, action: PayloadAction<CommonErrorType>) => {
      state.departedBoatChanging.status = Status.ERROR;
      state.departedBoatChanging.error = action.payload;
    },
    resetDepartedBoatError: (state) => {
      state.departedBoatChanging.status = Status.INIT;
      state.departedBoatChanging.error = null;
    },
    addWifiBoatLocation: (
      state,
      action: PayloadAction<WifiBoatLocationType | WifiBoatLocationType[]>
    ) => {
      if (Array.isArray(action.payload)) {
        state.wifiBoatLocation = action.payload;
      } else {
        state.wifiBoatLocation.push(action.payload);
      }
    },
    removeWifiBoatLocation: (
      state,
      action: PayloadAction<WifiBoatLocationType["boatId"]>
    ) => {
      state.wifiBoatLocation = state.wifiBoatLocation.filter(
        (arrow) => arrow.boatId !== action.payload
      );
    },
    setUserBoatValue: (
      state,
      actions: PayloadAction<{ gateId: GateType["id"]; value: string }>
    ) => {
      const gateIndex = state.pierGates.findIndex(
        (x) => x.id === actions.payload.gateId
      );
      state.pierGates[gateIndex].selectedBoat = {
        type: "USER_CHOOSE",
        value: actions.payload.value,
      };
    },
    swapGateSelectedValue: (state) => {
      const usersValues: GateType["selectedBoat"][] = [];
      const gateIds = state.pierGates.map((x) => x.id);
      state.pierGates.forEach((x, index) => {
        if (x.selectedBoat.type === "WIFI_CHOOSE") {
          const index = state.wifiBoatLocation.findIndex(
            (boat) => String(boat.boatId) === x.selectedBoat.value
          );
          if (index < 0) {
            return;
          }
          const newEntranceId = Number(
            gateIds.find(
              (id) =>
                id !== String(state.wifiBoatLocation[index].pierEntranceId)
            )
          );
          if (!newEntranceId) {
            return;
          }
          state.wifiBoatLocation[index].pierEntranceId = newEntranceId;
        } else {
          usersValues[index] = x.selectedBoat;
        }
      });
      if (usersValues.length > 0) {
        state.pierGates[0].selectedBoat = usersValues.at(1) ?? {
          type: "USER_CHOOSE",
          value: "",
        };
        state.pierGates[1].selectedBoat = usersValues.at(0) ?? {
          type: "USER_CHOOSE",
          value: "",
        };
      }
    },
    updateSelectedBoat: (state) => {
      state.pierGates.forEach(({ selectedBoat, id }, index) => {
        if (selectedBoat.type === "USER_CHOOSE" && selectedBoat.value) {
          return;
        }
        const firstWifiBoat = state.wifiBoatLocation.find(
          (x) => String(x.pierEntranceId) === id
        );
        if (firstWifiBoat) {
          state.pierGates[index].selectedBoat = {
            type: "WIFI_CHOOSE",
            value: String(firstWifiBoat.boatId),
          };
        } else {
          state.pierGates[index].selectedBoat = {
            type: "USER_CHOOSE",
            value: "",
          };
        }
      });
    },
  },
});

export const actions = pierSlice.actions;
export default pierSlice.reducer;
