export enum Status {
  INIT = "init",
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}
export type CommonErrorType = {
  code: string;
  message: string;
  errorCodes?: string[];
};
export type DataWithStatusType<T> = {
  status: Status;
  data: T | null;
  error: CommonErrorType | null;
};

export enum CountSensorStatus {
  UNDEFINED = "UNDEFINED",
  OK = "OK",
  DISCONNECTED = "DISCONNECTED",
  COVERED = "COVERED",
  TOO_DARK = "TOO_DARK",
  TOO_BRIGHT = "TOO_BRIGHT",
}

export enum IconStatus {
  SUCCESS,
  ERROR,
  DISABLE,
  NOT_PRESS,
}

export type { RootStateType, AppDispatchType } from "../store/store";
