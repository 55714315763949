import { Navigate } from "react-router-dom";

import { ReactComponent as Waves } from "../components/icons/WavesLogo.svg";
import LoginModule from "../modules/Login";
import { useSelector } from "../store";
import { isUserLogin } from "../store/currentUser/selectors";

import styles from "./index.module.css";

const Login = () => {
  const isLogin = useSelector(isUserLogin);

  if (isLogin) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.loginPage}>
      <Waves className={styles.loginWaves} />
      <div className={styles.loginForm}>
        <LoginModule />
      </div>
    </div>
  );
};
export default Login;
